{
  "name": "fitogest",
  "version": "18.22.0",
  "license": "UNLICENSED",
  "scripts": {
    "compile": "node buildWithInput.mjs",
    "start": "ng serve Fitogest",
    "start-fichajes": "ng serve Fichajes",
    "build-fichajes": "ng build Fichajes --base-href /proyectos/fitogest/fichajes/",
    "lint": "ng lint",
    "test": "ng test",
    "e2e": "ng e2e",
    "bundle": "npm pack",
    "compodoc": "npx compodoc -p src/tsconfig.app.json && compodoc src -s",
    "ng": "ng"
  },
  "dependencies": {
    "@angular/animations": "18.0.3",
    "@angular/cdk": "18.0.3",
    "@angular/common": "18.0.3",
    "@angular/compiler": "18.0.3",
    "@angular/core": "18.0.3",
    "@angular/forms": "18.0.3",
    "@angular/localize": "18.0.3",
    "@angular/platform-browser": "18.0.3",
    "@angular/platform-browser-dynamic": "18.0.3",
    "@angular/pwa": "18.0.4",
    "@angular/router": "18.0.3",
    "@angular/service-worker": "18.0.3",
    "@babel/core": "7.24.7",
    "@fullcalendar/angular": "6.1.14",
    "@fullcalendar/core": "6.1.14",
    "@fullcalendar/daygrid": "6.1.14",
    "@fullcalendar/interaction": "6.1.14",
    "@fullcalendar/timegrid": "6.1.14",
    "@google/maps": "1.1.3",
    "@ng-bootstrap/ng-bootstrap": "17.0.0",
    "@northpoint-labs/vector-tiles-google-maps": "1.1.0",
    "@popperjs/core": "2.11.8",
    "acorn": "8.12.0",
    "ajv": "8.16.0",
    "bootstrap": "5.3.3",
    "buffer": "6.0.3",
    "canvg": "^4.0.2",
    "chart.js": "4.4.3",
    "core-js": "^3.37.1",
    "crypto-js": "4.2.0",
    "dexie": "4.0.7",
    "font-awesome": "4.7.0",
    "fullcalendar": "6.1.14",
    "html2canvas": "1.4.1",
    "html2pdf": "0.0.11",
    "html2pdf-fix-jspdf": "0.0.4",
    "html2pdf.js": "0.10.1",
    "jspdf": "2.5.1",
    "jspdf-fix-png": "1.4.2",
    "moment": "2.30.1",
    "node-gyp": "10.1.0",
    "node-sass": "9.0.0",
    "octicons": "8.5.0",
    "ora": "^8.0.1",
    "pdf-text-extract": "1.5.0",
    "pdf-to-text": "0.0.7",
    "popper.js": "1.16.1",
    "primeicons": "7.0.0",
    "primeng": "17.18.1",
    "quill": "2.0.2",
    "rxjs": "7.8.1",
    "signature_pad": "5.0.2",
    "stream": "0.0.2",
    "timers": "0.1.1",
    "tippy.js": "^6.3.7",
    "ts-md5": "1.3.1",
    "util": "0.12.5",
    "xml2js": "0.6.2",
    "zone.js": "0.14.7"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "18.0.4",
    "@angular/cli": "18.0.4",
    "@angular/compiler-cli": "18.0.3",
    "@angular/language-service": "18.0.3",
    "@tsconfig/strictest": "2.0.5",
    "@types/jasmine": "5.1.4",
    "@types/jasminewd2": "2.0.13",
    "@types/xml2js": "0.4.14",
    "angular-eslint": "18.1.0",
    "eslint": "^9.6.0",
    "eslint-config-prettier": "9.1.0",
    "eslint-plugin-html": "8.1.1",
    "eslint-plugin-promise": "6.4.0",
    "globals": "15.8.0",
    "jasmine-core": "5.1.2",
    "jasmine-spec-reporter": "7.0.0",
    "karma": "6.4.3",
    "karma-chrome-launcher": "3.2.0",
    "karma-coverage-istanbul-reporter": "3.0.3",
    "karma-jasmine": "5.1.0",
    "karma-jasmine-html-reporter": "2.1.0",
    "process": "0.11.10",
    "protractor": "7.0.0",
    "ts-node": "10.9.2",
    "ts-string-literal-enum-plugin": "0.0.3",
    "typescript": "5.4.5",
    "typescript-eslint": "8.0.0-alpha.38",
    "typescript-todo-or-die-plugin": "0.6.0"
  },
  "engines": {
    "node": ">=18.19.0",
    "npm": ">=10.5.2"
  },
  "private": true,
  "bundleDependencies": [
    "@angular/animations",
    "@angular/cdk",
    "@angular/common",
    "@angular/compiler",
    "@angular/core",
    "@angular/forms",
    "@angular/localize",
    "@angular/platform-browser",
    "@angular/platform-browser-dynamic",
    "@angular/pwa",
    "@angular/router",
    "@angular/service-worker",
    "@babel/core",
    "@fullcalendar/angular",
    "@fullcalendar/core",
    "@fullcalendar/daygrid",
    "@fullcalendar/interaction",
    "@fullcalendar/timegrid",
    "@google/maps",
    "@ng-bootstrap/ng-bootstrap",
    "@northpoint-labs/vector-tiles-google-maps",
    "@popperjs/core",
    "acorn",
    "ajv",
    "bootstrap",
    "buffer",
    "chart.js",
    "core-js",
    "crypto-js",
    "dexie",
    "font-awesome",
    "fullcalendar",
    "html2canvas",
    "html2pdf",
    "html2pdf-fix-jspdf",
    "html2pdf.js",
    "jspdf",
    "jspdf-fix-png",
    "moment",
    "node-gyp",
    "node-sass",
    "octicons",
    "ora",
    "pdf-text-extract",
    "pdf-to-text",
    "popper.js",
    "primeicons",
    "primeng",
    "quill",
    "rxjs",
    "signature_pad",
    "stream",
    "timers",
    "tippy.js",
    "ts-md5",
    "util",
    "xml2js",
    "zone.js"
  ]
}
