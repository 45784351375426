import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class GraficasService {

    baseUrl = 'ws/tratamientos/charts/'

    constructor(
        private http: HttpClient
    ) {}


    getManezGraficas(id_tratamiento: string, n_tratamiento: string, id_maquinaria: string, fecha_tratamiento?: string) {
        if ( fecha_tratamiento == null){
            return this.http.get(
                environment.serverUrl  + this.baseUrl + 'get_charts_data.php?id_tratamiento=' + id_tratamiento +
                '&idTreatment=' + n_tratamiento + 
                '&idMaquinaria=' + id_maquinaria
            );
        } else {
            return this.http.get(
                environment.serverUrl  + this.baseUrl + 'get_charts_data.php?id_tratamiento=' + id_tratamiento +
                '&idTreatment=' + n_tratamiento + 
                '&idMaquinaria=' + id_maquinaria + 
                '&fecha=' + fecha_tratamiento
            );
        }
    }

    getManezGraficasCalendar(imei: string, fecha_tratamiento?: string) {
      
        return this.http.get(`${environment.serverUrl+this.baseUrl}get_charts_data_calendar.php?imei=${imei}&fecha=${fecha_tratamiento}`);
        
    }

}