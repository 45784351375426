import { features } from './features'; 
features.applicationType = 'fitosgest';
features.clientesName = 'Contratos';
features.clienteName = 'Contrato';
features.fincasName = 'Clientes';
features.fincaName = 'Cliente';
features.showRegistroTratamiento = true;
features.showRegistroTratamientoShort = true;
features.showVisitas = false;
features.showTrabajadoresUserYPass = true;
features.showTareasFincas = false;
features.showTareasSector = false;
features.showTractorVelocidad = true;
features.showTractorPresion = true;
features.showTractorRPM = true;
features.showTareasFitoCarretera = true;
features.showTareasFitoPuntoKM = true;
features.showTareasFitoDuracion = true;
features.showTareasParcela = false;
features.showTrabajadoresClienteAsignado = true;
features.showTrabajadoresMaquinaria = false;
features.showMaquinariaClienteAsignado = true;
features.showProductosFitoDosis = true;
features.showTareasFitoMochila = true;
features.showTareasFitoAplicacion = true;
features.showReto = true;
features.showVentas = false;
features.showTareasFitoPuntoKm = true;
features.forceHiddenVentas = true;

features.showBtnTratamiento = true;
features.showTratamientosMenu = false;

export const environment = {
    production: true,
    v: '1',
    serverUrl: 'https://innovia.fitosgest.com/',
    logoUrl: 'assets/logo_innovia.png',
    appName: 'innovia',
    features: features
};
