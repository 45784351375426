<br>
<div style="width: auto; overflow: auto">
    <app-common-list-table
            [request]="getRequest"
            [minWidth]="800"
            [cols]="cols"
            [filtering]="filter"
            [customExportCsv]="showTratamientosExportCsv"
            [globalConditionalStyle]="globalConditionalStyle"
            [globalVisualTransform]="globalVisualTransform"
            [actionButtonText]="canAddTratamientos ? btnAnadir : ''"
            (actionButtonClick)="add()">

        <ng-template #header>
             <div *ngIf="this.showAllTratamientos" class="d-flex justify-content-start align-content-center">
                <p-inputSwitch
                        [style]="{marginLeft: '15px', marginRight: '10px'}"
                        [(ngModel)]="showAllTratamientos"
                        (onChange)="changeShowAll();filterTratamientos()">
                </p-inputSwitch>
                <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todos</p>
            </div>
        </ng-template>

        <ng-template #thEnd>
            <th>
                <p class="d-flex justify-content-center"
                   style="margin-bottom: 0">Acciones</p>
            </th>
        </ng-template>

        <ng-template #tdEnd let-data>
            <td>
                <app-action-buttons
                        [showDuplicate]="canDuplicateTratamientos"
                        (duplicateRowButtonEvent)="duplicate(data)"
                        (editRowButtonEvent)="edit(data)"
                        (deleteRowButtonEvent)="show('delete', data)"
                        (showRowButtonEvent)="show('info', data)">
                </app-action-buttons>
            </td>
        </ng-template>

    </app-common-list-table>
</div>

<p-dialog
        header="Detalle del registro"
        [(visible)]="canShow['info']"
        (onShow)="scrollToTop()">

    <div *ngIf="canShow['info']" style="width: 80vw !important">
        <app-form-builder
                [model]="model"
                [distributionColumns]="3"
                [fields]="formFields">
        </app-form-builder>
        <br>
        <table class="table-style">
            <tr>
                <th style="width: 15px; border: none"></th>
                <th>Producto</th>
                <th>Uso</th>
                <th>Agente</th>
                <th *ngIf="!hiddeDosisCuaderno">
                    <span *ngIf="!enableLegalMode">C.&nbsp;Cuaderno</span>
                    <span *ngIf="enableLegalMode">Cantidad&nbsp;1</span>
                </th>
                <th *ngIf="!hiddeDosisAplicada">
                    <span *ngIf="!enableLegalMode">C.&nbsp;Aplicada</span>
                    <span *ngIf="enableLegalMode">Cantidad&nbsp;2</span>
                </th>
                <th>F.&nbsp;Caducidad</th>
            </tr>
            <tr *ngFor="let item of (model?.linea || []); let i = index"
                [style.background]="item?.producto?.caducado"
                [style.color]="item?.producto?.caducado == '#222222' ? 'white' : 'black' ">
                <td style="border: none; background: #f8f9fa !important; color: black !important;">{{i + 1}}</td>
                <td>{{item?.producto?.nombre}}</td>
                <td>{{item?.uso_agente?.uso}}</td>
                <td>{{item?.uso_agente?.agente}}</td>
                <td *ngIf="!hiddeDosisCuaderno">
                    <span *ngIf="item?.cantidad_cuaderno">
                        {{decimalFormat(item?.cantidad_cuaderno, 2, '.', ',', 3)}} {{splitFormat(showUdsCuaderno ? model?.uds_medida_cuaderno : model?.uds_medida )[i]}}
                    </span>
                </td>
                <td *ngIf="!hiddeDosisAplicada">
                    <span *ngIf="item?.cantidad_real">
                        {{decimalFormat(item?.cantidad_real, 2, '.', ',', 3)}} {{splitFormat(model?.uds_medida)[i]}}
                    </span>
                </td>
                <td>{{item?.producto?.vademecum?.fecha_caducidad || 'No figura'}}</td>
            </tr>
        </table>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('info')">Cerrar</button>
        </div>
    </div>
    <br>

</p-dialog>

<p-dialog
        header="Eliminar registro"
        [(visible)]="canShow['delete']"
        (onShow)="scrollToTop()">

    <div *ngIf="canShow['delete']" style="width: 80vw !important">
        <app-form-builder
                [model]="model"
                [distributionColumns]="3"
                [fields]="formFields">
        </app-form-builder>
        <br>
        <table class="table-style">
            <tr>
                <th style="width: 15px; border: none"></th>
                <th>Producto</th>
                <th>Uso</th>
                <th>Agente</th>
                <th *ngIf="!hiddeDosisCuaderno">
                    <span *ngIf="!enableLegalMode">C.&nbsp;Cuaderno</span>
                    <span *ngIf="enableLegalMode">Cantidad&nbsp;1</span>
                </th>
                <th *ngIf="!hiddeDosisAplicada">
                    <span *ngIf="!enableLegalMode">C.&nbsp;Aplicada</span>
                    <span *ngIf="enableLegalMode">Cantidad&nbsp;2</span>
                </th>
                <th>F.&nbsp;Caducidad</th>
            </tr>
            <tr *ngFor="let item of model?.linea; let i = index">
                <td style="border: none">{{i + 1}}</td>
                <td>{{item?.producto?.nombre}}</td>
                <td>{{item?.uso_agente?.uso}}</td>
                <td>{{item?.uso_agente?.agente}}</td>
                <td *ngIf="!hiddeDosisCuaderno">{{item?.cantidad_cuaderno}}</td>
                <td *ngIf="!hiddeDosisAplicada">{{item?.cantidad_real}}</td>
                <ng-container *ngIf="item?.producto?.vademecum?.fecha_caducidad">
                    <td>{{item?.producto?.vademecum?.fecha_caducidad}}</td>
                </ng-container>
                <ng-container *ngIf="!item?.producto?.vademecum?.fecha_caducidad">
                    <td>...</td>
                </ng-container>
            </tr>
        </table>
        <br>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
            <app-request-button
                    (click)="deleteRegister()"
                    [request]="deleteRequest"
                    [mode]="deleteRequestButtonType">
            </app-request-button>
        </div>
        <br>
        <p *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
           style="color: red; text-align: center">
            ¿Está seguro de eliminar este registro?
        </p>
    </div>
    <br>
</p-dialog>
