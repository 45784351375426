import { TableModule } from 'primeng/table';
import {Component, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {TrabajadoresApiService} from '../../../../service/api/trabajadores-api.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {RequestHandler} from '../../../../service/OffService/request-handler';
import {RequestButtonTypes} from '../../../../common/components/request-button/request-button.component';
import {BaseView} from '../../../base-view';
import {environment} from '../../../../../environments/environment';
import moment from 'moment';
import { Filtering } from 'src/app/service/filtering/filtering';
import { StorageManager } from 'src/app/common/storage-manager.class';
import { Utils } from 'src/app/common/utils';
import { TrabajadoresService } from 'src/app/service/trabajadores/trabajadores.service';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-mantenimiento',
    templateUrl: './mantenimiento.component.html',
    styleUrls: ['./mantenimiento.component.scss'],
    providers: [MessageService]
})
export class MantenimientoComponent extends BaseView implements OnInit {


    @ViewChild('table_cargos', {read: false, static: false}) table_cargos: TableModule = new TableModule();

    public appName = environment.appName;
    public undoEnabled = false;
    public applicationType = environment.features.applicationType;
    public currentUser = StorageManager.getUser();
    public showCostes = environment.features.showCostes;
    public showTrabajadoresCargos = environment.features.showTrabajadoresCargos;
    public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;
    public aplicadorName = environment.features.aplicadorName;
    public addText = 'Añadir ' + this.aplicadorName;
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    public trabajadoresAsUsuarios = environment.features.trabajadoresAsUsuarios;
    public showTrabajadoresCuadrillas = environment.features.showTrabajadoresCuadrillas;
    public showTrabajadoresNumSS = environment.features.showTrabajadoresNumSS;
    public showTrabajadoresMaquinaria = environment.features.showTrabajadoresMaquinaria;
    public canEditTrabajadores = environment.features.canEditTrabajadores;
    public showTrabajadoresClienteAsignado = environment.features.showTrabajadoresClienteAsignado;
    public clienteName = environment.features.clienteName;
    public hasClients = environment.features.hasClients;
    public userCanSeeClientes = this.hasClients &&
        (environment.features.userCanSeeClientes ? true : (StorageManager.getUser().rol === 'admin'));
    public showFotoTrajador = environment.features.showFotoTrajador; 
    public titleActivo = environment.features.titleActivoMaqTra;
    public showTrabajadoresUserYPass = environment.features.showTrabajadoresUserYPass;

    public filter: Filtering<any> = new Filtering<any>();
    
    public distributionOrder = [
        4, 4, 4
    ];

    public getRequest: RequestHandler = this.trabajadoresApi.trabajadoresGET;
    public deleteRequest: RequestHandler = this.trabajadoresApi.trabajadoresDELETE;
    public getRequestCargos: RequestHandler = this.trabajadoresApi.cargosGET;

    public pathToForm = 'mantenimiento-form';
    public menuItemId = 'mantenimiento';

    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public override canShow: { [key: string]: any } = {};
    public override model: any = {};
    public modelCargos: any = {
        nombre: '',
        precio_hora: 0.0
    };
    public componentName = 'trabajadores';

    public propiedad = {
        selected: ''
    };

    public formFieldsCargos: ItemInterface<any>[] = [
        {label: 'Cargo', field: 'nombre', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Precio/Hora', field: 'precio_hora', inputType: {type: InputType.EDIT_NUMERIC}},
    ];

    public formFields: ItemInterface<any>[] = [
        {label: 'Usuario', field: 'username', inputType: {type: InputType.TEXT}, visible: this.trabajadoresAsUsuarios},
        {label: 'Nombre', field: 'nombre', inputType: {type: InputType.TEXT}},
        {label: 'Apellidos', field: 'apellidos', inputType: {type: InputType.TEXT}},
        {label: 'NIF', field: 'nif', inputType: {type: InputType.TEXT}},
        {label: 'Password', field: 'password', inputType: {type: InputType.TEXT}, visible: this.showTrabajadoresUserYPass},
        {label: 'Cuadrilla', field: 'nombre_cuadrilla', inputType: {type: InputType.TEXT}, visible: this.showTrabajadoresCuadrillas},
        {label: 'Nº\xa0Afiliación', field: 'numss', inputType: {type: InputType.TEXT}, visible: this.showTrabajadoresNumSS},
        {label: 'Máquina', field: 'maquinaria', inputType: {type: InputType.TEXT}},
        {label: 'Ocupación', field: 'ocupacion', inputType: {type: InputType.TEXT}, visible: (this.appName === 'naturfrut')},
        {label: 'Tipo Carnet', field: 'tipo_carnet', inputType: {type: InputType.TEXT}},
        {label: 'ROPO', field: 'ropo', inputType: {type: InputType.TEXT}},
        {label: 'Fecha Expedición', field: 'fecha_exp', inputType: {type: InputType.TEXT}},
        {label: 'Fecha Caducidad', field: 'fecha_cad', inputType: {type: InputType.TEXT}},
        {label: 'Cargo', field: 'cargo', inputType: {type: InputType.TEXT}, visible: this.showTrabajadoresCargos},
        {label: 'Email', field: 'email', inputType: { type: InputType.TEXT}, visible: this.appName === 'fotoagricultura' },
        {label: 'Observaciones', field: 'observaciones', inputType: {type: InputType.TEXT}},
        {
            label: 'Proveedor',
            field: 'proveedor',
            inputType: {type: InputType.TEXT},
            visible: this.appName === 'sirga',
        },
        {label: 'Propiedad', field: 'propiedad', inputType: {type: InputType.TEXT}, visible: this.appName === 'agropecuariaramirogruas' },
        {
            label: this.titleActivo, 
            field: 'activo', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.showAplicadoresActivo, 
            style: {'margin-top': '0px'} 
        },
        {
            label: 'Acceso a Plataforma', 
            field: 'acceso_plataforma', 
            inputType: {type: InputType.RO_SWITCH}, 
            visible: this.appName === 'innovia', 
            style: {'margin-top': '0px'} 
        },
        {label: this.clienteName + ' asignado', field: 'cliente_nombre', inputType: {type: InputType.TEXT},
            visible: this.showTrabajadoresClienteAsignado},
        {label: '', field: 'adjunto', inputType: {type: InputType.EMPTY}, visible: this.showFotoTrajador }
    ];

    public cols: any[] = [
        {header: 'Usuario', field: 'username', visible: this.trabajadoresAsUsuarios},
        {header: 'Nombre', field: 'nombre'},
        {header: 'Apellidos', field: 'apellidos'},
        {header: 'NIF', field: 'nif'},
        {header: 'Cuadrilla', field: 'nombre_cuadrilla', visible: this.showTrabajadoresCuadrillas},
        {header: 'Carnet', field: 'tipo_carnet'},
        {header: 'ROPO', field: 'ropo'},
        {header: 'F.\xa0Caducidad', field: 'fecha_cad'},
        {header: 'Máquina', field: 'maquinaria', visible: this.showTrabajadoresMaquinaria},
        {header: 'Ocupación', field: 'ocupacion', visible: (this.appName === 'naturfrut')},
        {header: 'Propiedad', field: 'propiedad', visible: (this.appName === 'agropecuariaramirogruas')},
        {header: this.titleActivo, field: 'activo', width: '60px', visible: this.showAplicadoresActivo},
        {header: this.clienteName + ' asignado', field: 'cliente_nombre', visible: this.showTrabajadoresClienteAsignado},
        {header: 'Cargo', field: 'cargo', visible: this.showTrabajadoresCargos}
    ];

    public colsCargos: any[] = [
        {header: 'Nombre', field: 'nombre'},
        {header: 'Precio', field: 'precio_hora', width: '80px'}
    ];
    public trabajadores: any [] = [];
    public imagenAdjunto: any;

    constructor(
        public trabajadoresApi: TrabajadoresApiService,
        private dashboard: DashboardService,
        private router: Router,
        private trabajadoresService: TrabajadoresService,
        private messageService: MessageService,
    ) {
        super(dashboard, trabajadoresApi.trabajadoresGET, trabajadoresApi.trabajadoresDELETE);
    }

    ngOnInit() {
        this.dashboard.setSubmenuItem(this.menuItemId);
        this.trabajadoresApi.cargosGET.toPromise()
            .then( trabajadores => {
                this.trabajadores = trabajadores;
            });
        this.initFilter();
    }

    updateCargo( trabajador: { id: any; } ) {
       (firstValueFrom(this.trabajadoresService.updateTrabajadores(trabajador, trabajador.id) as any))
            .then( () => {
            //alert('Registro de cargo guardado correctamente ' );
                this.messageService.add({severity: 'warn', summary: 'Registro', detail: ' Registro actualizado correctamente'});
            })
            .catch( error => {
                alert('Registro de cargo no se ha podido guardar ' + JSON.stringify(error) );
                // this.messageService.add({severity:'warn', summary:'Registro', detail:'Error al actualizar registro'});

            });    
        
    }
    
    deleteCargo( trabajador: { id: any; } ) {
       (firstValueFrom(this.trabajadoresService.deleteTrabajadores(trabajador.id) as any))
            .then( () => {
            //alert('Registro de cargo guardado correctamente ' );
                this.messageService.add({severity: 'warn', summary: 'Registro', detail: ' Registro eliminado correctamente'});
                window.location.reload();
            })
            .catch( error => {
                alert('Registro de cargo no se ha podido eliminar ' + JSON.stringify(error) );
                // this.messageService.add({severity:'warn', summary:'Registro', detail:'Error al actualizar registro'});

            });    
        
    }

    editCargo(index: number) {
        document?.getElementById('precioHora' + index )?.focus();    
    }

    insertarCargo() {
        this.trabajadoresApi.cargosPOST.perform(this.modelCargos);
        
        this.trabajadoresApi.cargosPOST.response(() => {
            this.trabajadoresApi.cargosPOST.unsuscribe();
            window.location.reload();
        });
    }

    public filtrarTrabajadores(event: any){
        if (event.target.value === 'Propios') {
            this.propiedad.selected = 'Propios';
        }
        else if (event.target.value === 'Cliente'){
            this.propiedad.selected = 'Cliente';
        }
        else{
            this.propiedad.selected = 'Todos';
        }
        this.filter.filter();
    }// ();


    public globalConditionalStyle(value: any, col: any, _colGroup: any, _row: any) {

        if (col.field === 'nombre') {
            return {
                textAlign: 'left'
            };
        }

        if (col.field === 'activo') {
            return {
                textAlign: 'center'
            };
        }
        
        if (col.field === 'fecha_cad') {
            const fecha = moment(value, 'DD/MM/YYYY');
            const tresMesesAntes = moment(value, 'DD/MM/YYYY').subtract(3, 'months');
            const unAñoAntes = moment(value, 'DD/MM/YYYY').subtract(1, 'year');
            const hoy = moment(moment().format('MM-DD-YYYY' + ' 00:00:00'));

            const fechaAviso = this.appName === 'ava' ? unAñoAntes : tresMesesAntes;

            if (hoy.isSameOrAfter(fechaAviso) && hoy.isSameOrBefore(fecha)) {
                return {
                    rowStyle: {
                        backgroundColor: '#ff9a02',
                        textAlign: 'center'
                    }
                };
            } else if (hoy.isAfter(fecha)) {
                return {
                    rowStyle: {
                        backgroundColor: '#f44242',
                        textAlign: 'center'
                    }
                };
            } else {
                return {
                    rowStyle: {
                        textAlign: 'center'
                    }
                };  
            }
        }
       
        if (Utils.isNumber(value) || col.field === 'ropo' ) {
            return {
                textAlign: 'right',
                paddingRight: '40px'
            };
        }
        return{ textAlign: 'left'};

    }

    public globalVisualTransform(value: any, col: { field: string; }): any {
        if (col.field === 'activo'){
            return ([1, true, '1', 'Sí'].includes(value ?? '')) ? 'Sí' : 'No';
        }
        if (col.field === 'fecha_cad' && !moment(value, 'DD/MM/YYYY').isValid()) {
            return '-';
        }
    }

    public add() {
        if (this.canEditTrabajadores) {
            if (this.filterAplicadoresByClient && !StorageManager.getClient() && this.userCanSeeClientes) {
                this.dashboard.openClientDialog();
            } else {
                this.router.navigate(['dashboard', 'crear-mantenimiento']);
            }
        }
    }

    public edit(data: any) {
        if (this.canEditTrabajadores) {
            this.router.navigate(['dashboard', 'editar-mantenimiento', data.id]);
        }
    }

    public duplicate(data: any) {
        if (this.canEditTrabajadores) {
            this.router.navigate(['dashboard', 'duplicar-mantenimiento', data.id]);
        }
    }

    public override deleteRegister() {
        if (this.canEditTrabajadores) {
            super.deleteRegister();
        }
    }

    public override show(modal: string, data: any) {
        super.show(modal, data);
        
        if (this.model) {
            this.model.activo = [1, '1', true, 'true'].includes(this.model.activo);
            if (this.showFotoTrajador) {
                this.getVisitImages();
            }

            if (this.showTrabajadoresFirma) {
                fetch(this.pathToFirmas + '_' + this.componentName + '/' + (this.model.id || 0) + '.png').then(res => {
                    if (res.status === 200) {
                        this.model.has_signature = true;
                    }
                });
            }
        }
      
    }

    public getVisitImages() {
        console.log('model', this.model.adjunto);
        
        if (this.model.adjunto) {
            fetch(environment.serverUrl + 'fotos_trabajadores/' + (this.model.adjunto || {} as any))
                .then((r) => {
                    if (r.status === 200) {
                        this.imagenAdjunto = environment.serverUrl + 'fotos_trabajadores/' + (this.model.adjunto  || {} as any);
                    }
                });
        } else {
            this.imagenAdjunto = null;
        }
    }

    public verAdjunto() {
        if (this.model.adjunto) {
            const myWindow = window.open('about:blank', '_blank', 'width=600, height=600');
            myWindow?.location.assign(environment.serverUrl + 'fotos_trabajadores/' + this.model.adjunto);
            myWindow?.focus();
        } else {
            alert('Esta trabajdor no tiene fotos.');
        }
    }

    public formChanges() {

    }

    private initFilter(){
        if (this.appName === 'agropecuariaramirogruas'){
            this.propiedad.selected = 'Todos';
            this.filter.addFilter((copy: any[]) => copy.filter(row => {
                switch (this.propiedad.selected) {
                    case 'Propios':
                        return row.propiedad === 'Propios';
                    case 'Cliente':
                        return row.propiedad === 'Cliente';
                    default:
                        return true;
                }// switch();
            }));
        }
        else if (this.appName === 'innovia'  && this.currentUser.rol === 'user'){
            this.filter.addFilter((data: any) => (data || [])
                .filter((it: any) => it)
                .filter((it: { id_cliente: string | undefined; }) => this.currentUser.id_cliente === it.id_cliente)
            );
        }// if();
    }
}
