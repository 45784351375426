import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {DashboardService} from '../../../service/events/dashboard.service';
import {ClientesApiService} from '../../../service/api/clientes-api.service';
import {InputType} from '../../../common/components/form-builder/form-builder.component';
import {RequestButtonTypes} from '../../../common/components/request-button/request-button.component';
import {RequestHandler} from '../../../service/OffService/request-handler';
import {BaseView} from '../../base-view';
import {StorageManager} from '../../../common/storage-manager.class';
import {Subscription} from 'rxjs';
import {Filtering} from '../../../service/filtering/filtering';
import {build, list, stack} from '../../../common/classes/request-builder';
import {FincasApiService} from '../../../service/api/fincas-api.service';
import { environment } from 'src/environments/environment';
import { SdiApiService } from 'src/app/service/api/sdi.service';
import { Utils } from '../../../common/utils';
import { createCsv } from 'src/app/common/createCsv';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-clientes',
    templateUrl: './clientes.component.html',
    styleUrls: ['./clientes.component.scss']
})
export class ClientesComponent extends BaseView implements OnInit {

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public serverUrl = environment.serverUrl;
    public rol: string = StorageManager.getUser().rol;
    public showCuadernoDeCampoPorCliente = environment.features.showCuadernoDeCampoPorCliente;
    public showFincasLibroFertilizantes = environment.features.showFincasLibroFertilizantes;
    public showFincasInformeTratamientos = environment.features.showFincasInformeTratamientos;

    public showMostrarTodosClientes = environment.features.showMostrarTodosClientes;
    public showTecnicoAsignado = (
        environment.appName !== 'melia' && 
            environment.appName !== 'gardengest' && 
            environment.appName !== 'covidai'
    );
    public clientesMostrarTodosDefault = environment.features.clientesMostrarTodosDefault;
    public canAddClients = environment.features.canAddClients;
    public canEditClients = this.rol !== 'responsable' && environment.features.canEditClients;
    public canDeleteClients = this.rol !== 'responsable' && environment.features.canDeleteClients;
    public showAllClients = this.clientesMostrarTodosDefault;
    public showClientesTecnico = environment.features.showClientesTecnico;
    public showClientesObservaciones = environment.features.showClientesObservaciones;
    public showSdiClientes = environment.features.showSdiClientes;
    public showClientesCooperativa = environment.features.showClientesCooperativa;
    public showClientesRegepa = environment.features.showClientesRegepa;
    public showClientesFirma = environment.features.showClientesFirma;
    public clienteName = environment.features.clienteName;
    public clientesName = environment.features.clientesName;

    
    public isShowingSdiClientes: boolean = false;
    public showClientesCsv = environment.features.showClientesCsv;
    public showClientesNotas = environment.features.showClientesNotas;
    public showFieldsClientesEstadoReglas = environment.features.showFieldsClientesEstadoReglas;


    public getRequest: RequestHandler = this.clientesApi.clientesGET;
    public getRequestSdi: RequestHandler = this.sdiApi.SdiClientesGET;
    public deleteRequest: RequestHandler = this.clientesApi.clientesDELETE;
    public currentClient: any;
    public currentUser: any;
    public fincas = list();
    public deleteWarningMessage: string = '';
    public deleteWarning: string = '';

    public user = StorageManager.getUser();

    public componentName = 'clientes';

    public sub: Subscription = new Subscription;
    public filter: Filtering<any> = new Filtering<any>();
    
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public generarFields: ItemInterface<any>[] = [
        {
            field: 'fecha_inicio',
            label: 'Fecha inicio',
            inputType: { type: InputType.CALENDAR }
        },
        {
            field: 'fecha_fin',
            label: 'Fecha fin',
            inputType: { type: InputType.CALENDAR }
        }
    ];

    public formFields: ItemInterface<any>[] = [
        {label: 'Nombre o Razón Social', name: 'Nombre o Razón Social', field: 'nombre', inputType: {type: InputType.TEXT}},
        {label: 'NIF\xa0/\xa0CIF', name: 'NIF\xa0/\xa0CIF', field: 'nif', inputType: {type: InputType.TEXT}},
        {label: 'Sector', name: 'Sector', field: 'sector', inputType: {type: InputType.TEXT}, visible: this.appName === 'crm'},
        {label: 'Cooperativa', name: 'Cooperativa', field: 'cooperativa', inputType: {type: InputType.TEXT},
            visible: this.showClientesCooperativa},
        {label: 'Dirección', name: 'Dirección', field: 'direccion', inputType: {type: InputType.TEXT}},
        {label: 'Localidad', name: 'Localidad', field: 'localidad', inputType: {type: InputType.TEXT}},
        {label: 'Código Postal', name: 'Código Postal', field: 'codigo_postal', inputType: {type: InputType.TEXT}},
        {label: 'Provincia', name: 'Provincia', field: 'provincia', inputType: {type: InputType.TEXT}},
        {label: 'Teléfono', name: 'Teléfono', field: 'telefono', inputType: {type: InputType.TEXT}},
        {label: 'Email', name: 'Email', field: 'email', inputType: {type: InputType.TEXT}},
        {
            label: 'Gerente',
            field: 'gerente',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Ewail del Gerente',
            field: 'email_gerente',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Teléfono del Gerente',
            field: 'telefono_gerente',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Responsable de Calidad',
            field: 'responsable_calidad',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Email del Responsable de Calidad',
            field: 'email_responsable_calidad',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            label: 'Teléfono del Responsable de Calidad',
            field: 'telefono_responsable_calidad',
            inputType: {type: InputType.TEXT},
            visible: environment.features.showCrm
        },
        {
            field: 'operador_explotacion', 
            name: 'Nº Operador explotación',
            label: 'Nº Operador explotación',
            inputType: {type: InputType.TEXT},          
            visible: environment.appName === 'pyf'
        },
        {label: 'REGEPA', name: 'REGEPA', field: 'regepa', inputType: {type: InputType.TEXT}, visible: this.showClientesRegepa},
        {
            label: this.appName === 'crm' ? 'Comercial Asignado' : 'Técnico Asociado', 
            name: this.appName === 'crm' ? 'Comercial Asignado' : 'Técnico Asociado', 
            field: 'usuario_asignado_nombre', 
            inputType: {type: InputType.TEXT},
            visible: this.showTecnicoAsignado
        },
        {label: 'Tipo', name: 'Tipo', field: 'tipo', inputType: {type: InputType.TEXT},
            visible: this.appName === 'agrosalvi' || environment.features.showCrm},
        /*         {label: 'Técnico Asociado', field: 'usuario_asignado_nombre', inputType: {type: InputType.TEXT},
            visible: this.showMostrarTodosClientes}, */
        {label: 'Observaciones', name: 'Observaciones', field: 'observaciones', inputType: {type: InputType.TEXTAREA},
            visible: this.showClientesObservaciones},
        {label: 'Notas', name: 'Notas', field: 'notas', inputType: {type: InputType.EDIT_TEXTAREA},
            visible: this.showClientesNotas},
        {
            label: 'Acceso a Plataforma', 
            field: 'acceso_plataforma', 
            inputType: {type: InputType.TEXT}, 
            visible: this.applicationType === 'cropgest' && this.rol === 'admin', 
            style: {'margin-top': '0px'} 
        },
    ];

    public formFieldsSdi: ItemInterface<any>[] = [
        {label: 'Nombre', field: 'nomcli', inputType: {type: InputType.TEXT}},
        {label: 'Razón', field: 'razon', inputType: {type: InputType.TEXT}},
        {label: 'NIF', field: 'nifcli', inputType: {type: InputType.TEXT}},
        {label: 'Dirección', field: 'dircli', inputType: {type: InputType.TEXT}},
        {label: 'Dto.', field: 'dtocli', inputType: {type: InputType.TEXT}},
        {label: 'Provincia', field: 'nomprovi', inputType: {type: InputType.TEXT}},
        {label: 'Población', field: 'pobcli', inputType: {type: InputType.TEXT}},
        {label: 'Teléfono', field: 'telcli', inputType: {type: InputType.TEXT}},
        {label: 'Email', field: 'email', inputType: {type: InputType.TEXT}}
    ];

    public cols = [
        {field: 'nombre', header: 'Nombre o Razón Social', filter: '', width: '200px'},
        {field: 'direccion', header: 'Dirección', filter: ''},
        {field: 'nif', header: 'NIF\xa0/\xa0CIF', filter: ''},
        {field: 'localidad', header: 'Localidad', filter: ''},
        {field: 'email', header: 'Email', filter: '', width: '200px'},
        {field: 'usuario_asignado_nombre', header: 'Técnico', filter: '', visible: this.showTecnicoAsignado},
        {field: 'observaciones', header: 'Observ.', filter: '', visible: this.showClientesObservaciones, width: '40px'},
        {field: 'cooperativa', header: 'Cooperativa', visible: this.showClientesCooperativa},
        {field: 'tipo', header: 'Tipo', visible: this.appName === 'agrosalvi'},
    ];
    // TODO: sequiador terminar

    public colsSdi = [
        {field: 'nomcli', header: 'Nombre', filter: '', width: '160px'},
        {field: 'razon', header: 'Razón', filter: '', width: '160px'},
        {field: 'nifcli', header: 'NIF', filter: ''},
        {field: 'dircli', header: 'Dirección', filter: ''},
        {field: 'dtocli', header: 'Dto.', filter: '', width: '60px'},
        {field: 'nomprovi', header: 'Provincia', filter: ''},
        {field: 'pobcli', header: 'Población', filter: ''},
        {field: 'telcli', header: 'Teléfono', filter: ''},
        {field: 'email', header: 'Email', filter: '', width: '160px'}
    ];
    public formFieldsEstadoReglas: ItemInterface<any>[] = [
        {label: 'Estado', field: 'estado', inputType: { type: InputType.TEXT} },
        {label: 'Reglas de negocio', field: 'regla_negocio', inputType: { type: InputType.TEXT}},
    ];

    constructor(private router: Router,
        private dashboard: DashboardService,
        public clientesApi: ClientesApiService,
        public sdiApi: SdiApiService,
        public fincasApi: FincasApiService
    ) {
        super(dashboard, clientesApi.clientesGET, clientesApi.clientesDELETE);
    }


    ngOnInit() {
        this.showAllClients = Utils.initShowAll('showAllclientes');
        this.dashboard.setMenuItem('clientes');
        this.dashboard.changeClientActive = true;
        this.currentClient = StorageManager.getClient() || {};
        this.currentUser = StorageManager.getUser() || {};
        this.sub = this.dashboard.onChangeClient()
            .subscribe(() => {
                this.currentClient = StorageManager.getClient();
            });
        if (this.currentUser.rol === 'admin' && environment.appName !== 'torrepacheco') {
            this.showAllClients = true;
        }

        if (environment.appName === 'donjardin') {
            this.showAllClients = true;
        }

        if (this.appName === 'agrosalvi') {
            this.showAllClients = false;
        }
        
        this.initData();
        this.initFilter();
    }

    public filterClients() {
        this.filter.filter();
    }

    public selectClient(data: any) {
        StorageManager.saveClient(data);
        this.dashboard.emitClientChange();
    }

    public add() {
        this.router.navigate(['dashboard', 'crear-cliente']);
    }

    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-cliente', data.id]);
    }

    public checkIfDeletedClientWasSelected(clientId: string) {
        const clientSelected = StorageManager.getClient();

        if ((clientSelected || {}).id === clientId) {
            StorageManager.deleteClient();
            this.dashboard.emitClientChange();
        }
    }

    public override deleteRegister() {
        if (this.model['id']) {
            const fincaCliente = (this.fincas.values || [])
                .filter(it => it && it.value)
                .map(it => it.value)
                .find(finca => finca.id_cliente === this.model['id']);

            if (!fincaCliente || this.deleteWarning) {
                this.deleteWarning = '';
                this.deleteWarningMessage = '';
                this.checkIfDeletedClientWasSelected(this.model['id']);

                this.deleteRequest.safePerform(this.model);
                this.deleteRequest.response(() => {
                    if (!this.deleteRequest.hasError) {
                        this.getRequest.safePerform();
                    }
                });
            } else {
                this.deleteWarningMessage = 'El cliente tiene datos, se borrara toda la información asociada';
                this.deleteWarning = '¡Cliente con datos! ¿Continuar?';
            }
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    switchSdi() {
        this.isShowingSdiClientes = !this.isShowingSdiClientes;
    }
      
    public globalConditionalStyle(_value: any, col: { field: string; }, _row:  any): any {
        if (col.field === 'observaciones' || col.field === 'localidad' || col.field === 'usuario_asignado_nombre' || col.field === 'nif') {
            return {
                textAlign: 'center'
            };
        }
        
        /*if (col.field === "acceso_plataforma") {
            switch(value) {
                case "0":
                    return { backgroundColor: '#e91224' };
                case "1":
                    return { backgroundColor: '#34a835' };
            }
        }*/
    }

    public globalVisualTransform(value: any, col: { field: string; }, row: { [x: string]: string; }) {
        if (col.field === 'observaciones') {
            return value ? 'Sí' : '';
        }
        if (col.field === 'nombre'){
            if (row['apellidos'] === null) {row['apellidos'] = ''; }

            /* Se concatena nombre y apellidos en el 'return' porque, en Agricar, esta función se ejecuta, 
                por algún motivo que desconocemos, cada vez que se hace una iteracción en la sección.
            */
            return row['razon_social'] !== '' 
                ? row['razon_social'] 
                : row['nombre'] + ' ' + row['apellidos'];
        }
        return value;
        

        
    }

    public override show(modal: string, data: any) {
        super.show(modal, data);
        if (this.showClientesFirma) {
            fetch(this.pathToFirmas + '_' + this.componentName + '/' + (this.model.id || 0) + '.png').then(res => {
                if (res.status === 200) {
                    this.model.has_signature = true;
                }
            });
        }
    }

    public override hide(modal: string) {
        this.model = null;
        this.model = null;
        this.canShow[modal] = false;
        this.deleteRequest.reset();
        this.deleteWarning = '';
        this.deleteWarningMessage = '';
    }

    public async exportarClientes() {
        window.location.replace(environment.serverUrl + 'fito_backup/exportar_clientes.php');
    }

    public async downloadCsv() {

        const arrayFormFields = [
            this.formFields
        ];
      
        
        const csvContent = createCsv(arrayFormFields, this.filter.value, 'notas' );
      
        const csvLink: any = document.getElementById('csvLink');
        csvLink.download = 'Clientes.csv';
        csvLink.href = encodeURI(csvContent);
        csvLink.click();
    }// ();

    // PETICIÓN GET PARA GENERAR CUADERNO 
    public generarCuaderno(informe_tratamientos = false, libro_fertilizantes = false) {
        if (!this.model.fecha_inicio || !this.model.fecha_fin) {
            alert('Debe especificar un intervalo de fechas.');
            return;
        }
        const cliente = this.model.nombre;
        const idCliente = this.model.id_cliente;
        const appName = this.appName;
        const user = StorageManager.getUser().id;
 
        let cuadernoFields = '?cliente=' + cliente +
        '&id_cliente=' + idCliente +
        '&app_name=' + appName +
        '&id_user=' + user;

        const interval: any = this.getDates(this.model.fecha_inicio, this.model.fecha_fin);
        const fechaFin = interval['fecha_fin'];
        const fechaInicio = interval['fecha_inicio'];
        const anyo2 = interval['anyo'];
        cuadernoFields += '&fecha_fin=' + fechaFin +
        '&fecha_inicio=' + fechaInicio + '&anyo=' + anyo2;

        if (informe_tratamientos) {
            cuadernoFields += '&informe_tratamientos=true';
        }
        if (libro_fertilizantes) {
            cuadernoFields += '&libro_fertilizantes=true';
        }
        window.open((this.serverUrl + 'ws/informes/index.php' + cuadernoFields), '_blank');
    }

    public getDates(inicio: string | Date, fin: string | Date): {} {
        const fechaInicio = (inicio instanceof Date) ? Utils.formatDate(inicio) : inicio;
        const fechaFin = (fin instanceof Date) ? Utils.formatDate(fin) : fin;
      
        // Si no hay año se calcula con los años de la fecha de inicio y de fin (2017-2018)
        let anyo = fechaInicio.split('/')[2];
        anyo = fechaInicio.split('/')[2] + '-' + fechaFin.split('/')[2];
        if (fechaInicio.split('/')[2] === fechaFin.split('/')[2]) { anyo = fechaInicio.split('/')[2]; }
      
        return {fechaInicio, fechaFin, anyo};
    }

    public changeShowAll(){
        sessionStorage.setItem('showAllclientes', this.showAllClients === true ? 'true' : 'false' );
    }    

    private initFilter() {
        if (this.appName === 'benibal') {
            this.filter.addFilter((data: any) => (data || [])
                .filter((it: any) => it)
                .filter((it: { acceso_plataforma: number; }) => !this.showAllClients 
                    ? it.acceso_plataforma === 1
                    : true)
            );
        } else if (this.appName === 'agrosalvi'){
            if (this.currentUser.tipo !== 'Agro + Garden'){
                this.filter.addFilter((data: any) => (data || [])
                    .filter((it: any) => it)
                    .filter((it: { tipo: any; }) => !this.showAllClients 
                        ? this.currentUser.tipo === it.tipo
                        : true)
                );
            }
        } else {
            this.filter.addFilter((data: any) => (data || [])
                .filter((it: any) => it)
                .filter((it: { id_usuario_asignado: any; id_usuario_asignado2: any; }) => !this.showAllClients 
                    ? [it.id_usuario_asignado, it.id_usuario_asignado2].includes(this.currentUser.id)
                    : true)
            );
        }

        if (this.appName === 'innovia' && this.currentUser.rol === 'user'){
            this.filter.addFilter((data: any) => (data || [])
                .filter((it: any) => it)
                .filter((it: { id: any; }) => this.currentUser.id_cliente === it.id)
            );
        }// if();
    }

    private initData() {
        stack([
            build(this.fincasApi.fincasGET, this.fincas, '', true)
        ]);
    }
}