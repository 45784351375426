import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class GenericService {

    constructor( private http: HttpClient ) {
        
    }


    resumeTronic(id_tratamiento: string, imei: string ) {
       
        return this.http.get(
            'https://appmyl.com/ws/index.php?p1=tratamientos&p2=get_tronic&n_tratamiento=' + id_tratamiento + 
            '&imei=' + imei
        );
    }

    resumeTronicCalendar( imei: string, fecha: string ) {
       
        return this.http.get('https://appmyl.com/ws/index.php?p1=tratamientos&p2=get_tronic&fecha=' + fecha + '&imei=' + imei);
    }

}