<p-panel>
  <h4>Nombre del tratamiento</h4>
  <div style="max-width: 1200px; margin: 0 auto;">
      <div class="row">
          <div class="col-12">
              <app-form-builder
                      [fields]="form.formFields"
                      [model]="model"
                      [distributionColumns]="4"
                      [undoEnabled]="false">
              </app-form-builder>
          </div>
      </div>
  </div>
  <br>
  <div style="max-width: 1000px; margin: 0 auto;">
      <h4>Añadir Productos</h4>
      <p class="small font-weight-bold">
          <b style="color: red;">*</b>: La unidad de medida es obligatoria si ha seleccionado un producto.
      </p>
      <div *ngIf="form?.productRows?.length === 0">
          <br>
          <h5 style="text-align: center;">Cargando...</h5>
          <br>
      </div>

      <div *ngFor="let row of (form?.productRows || []); let i = index" [attr.data-index]="i">
          <div class="custom-d-flex">
              <div>
                  <p style="text-align: right">{{i + 1}}</p>
              </div>
              <div style="flex: 3">
                  <p *ngIf="i === 0">Producto</p>
                  <p-dropdown [(ngModel)]="row.productos.selected"
                              (onChange)="applyFilters('productos', i)"
                              [options]="row.productos.filtered"
                              optionLabel="label"
                              filterBY="label"
                              optionValue="value"
                              [filter]="true"
                              [showClear]="true"
                              [style]="{maxWidth: '230px'}"></p-dropdown>
              </div>
              <div style="flex: 2">
                  <p *ngIf="i === 0">Uso</p>
                  <p-dropdown [(ngModel)]="row.usos.selected" 
                              (onChange)="applyFilters('usos', i)"
                              [options]="row.usos.filtered"
                              [filter]="true"
                              optionLabel="label"
                              filterBY="label"
                              optionValue="value"
                              [style]="{maxWidth: '150px'}"></p-dropdown>
              </div>
              <div style="flex: 2">
                  <p *ngIf="i === 0">Agente</p>
                  <p-dropdown [(ngModel)]="row.agentes.selected"
                              (onChange)="applyFilters('agentes', i)"
                              [filter]="row.agentes.filtered.length > 20" 
                              emptyFilterMessage="No hay resultados" 
                              [options]="row.agentes.filtered"
                              [filter]="true"
                              optionLabel="label"
                              filterBY="label"
                              optionValue="value"
                              [style]="{maxWidth: '150px'}"></p-dropdown>
              </div>
              <div style="flex: 1" *ngIf="!hiddeDosisCuaderno">
                  <p *ngIf="i === 0">
                      <span *ngIf="!enableLegalMode">Cuaderno</span>
                      <span *ngIf="enableLegalMode">Cant.&nbsp;1</span>
                  </p>
                  <input 
                      [class.invalid]="form.values.status[i] === 'invalid'"
                      [class.valid]="form.values.status[i] === 'valid'"
                         style="width: 100px !important"
                         (keyup)="checkDosis(i)"
                         [(ngModel)]="form.values.cantidadCuaderno[i]"
                         type="number"
                         placeholder="..."
                         pInputText/>

              </div>
              <div *ngIf="this.showUdsCuaderno" style="flex: 1">
                <p *ngIf="i === 0">
                  <span *ngIf="!enableLegalMode">Ud.&nbsp;Cuad.<b style="color: red;">*</b></span>
                  <span *ngIf="enableLegalMode">Ud.&nbsp;Medida 1<b style="color: red;">*</b></span>
                </p>
                <input [(ngModel)]="row.uds_medida_cuaderno"
                [value]="row.uds_medida_cuaderno ? row.uds_medida_cuaderno :
                  form.productRows[i]?.productos?.selected?.unidades ?
                  getMesureValue(form.productRows[i], 'uds_medida_cuaderno') : ''"
                       style="width: 70px !important"
                       placeholder="..."
                       pInputText/>
            </div>
              <div style="flex: 1" *ngIf="!hiddeDosisAplicada">
                  <p *ngIf="i === 0">
                      <span *ngIf="!enableLegalMode">Aplicada</span>
                      <span *ngIf="enableLegalMode">Cant.&nbsp;2</span>
                  </p>
                  <input [(ngModel)]="form.values.cantidadReal[i]"
                         style="width: 100px !important"
                         type="number"
                         placeholder="..."
                         pInputText/>
              </div>
              <div style="flex: 1">
                  <p *ngIf="i === 0">
                    <span *ngIf="!enableLegalMode">Ud.&nbsp;Aplicada<b style="color: red;">*</b></span>
                    <span *ngIf="enableLegalMode">Ud.&nbsp;Medida 2<b style="color: red;">*</b></span>
                  </p>
                  <input [(ngModel)]="row.uds_medida"
                  [value]="row.uds_medida ? row.uds_medida :
                    form.productRows[i]?.productos?.selected?.unidades ?
                    getMesureValue(form.productRows[i], 'uds_medida') : ''"
                         style="width: 70px !important"
                         placeholder="..."
                         pInputText/>
              </div>
              <div *ngIf="showTratamientosRecetaSwitch" style="flex: 1">
                  <p *ngIf="i === 0">
                      Oculto
                  </p>
                  <p-inputSwitch
                          [(ngModel)]="row.ocultar_en_receta"
                          [disabled]="!form.productRows[i]?.productos?.selected">
                  </p-inputSwitch>
              </div>
          </div>
          <div class="d-flex justify-content-center">
              <div style="margin-right: 10px;" *ngIf="row.agentes?.selected && row.usos?.selected?.label !== '-'">
                  <table *ngIf="appName === 'agroleza'" class="table-style">
                      <tr>
                          <th>Plazo Seguridad</th>
                          <th>Volumen Máximo de Caldo</th>
                          <th>Nº Máx. de Aplicaciones</th>
                          <th>Dosis</th>
                          <th>Intervalos</th>
                      </tr>
                      <tr>
                        <td>{{row.agentes.selected.plazo_seguridad}}</td>
                        <td>{{row.agentes.selected.vol_caldo}}</td>
                        <td>{{row.agentes.selected.n_aplicaciones}}</td>
                        <td><span style="
                          position: relative;
                          top: 5px">
                          {{decimalFormat(row.agentes.selected.dosis_min, 2, '.', ',', 3)}}
                          <span *ngIf="row.agentes?.selected?.dosis_max && row.agentes?.selected?.dosis_max !== '0'">
                            - {{decimalFormat(row.agentes.selected.dosis_max, 2, '.', ',', 3)}}
                          </span>
                          {{row.agentes.selected.unidad_medida}}
                        </span></td>
                        <td>{{row.agentes.selected.intervalos}}</td>
                      </tr>
                    </table>
        <span *ngIf="appName !== 'agroleza'" style="
          position: relative;
          top: 5px">
          Dosis. {{decimalFormat(row.agentes.selected.dosis_min, 2, '.', ',', 3)}}
          <span *ngIf="row.agentes?.selected?.dosis_max && row.agentes?.selected?.dosis_max !== '0'">
            - {{decimalFormat(row.agentes.selected.dosis_max, 2, '.', ',', 3)}}
          </span>
          {{row.agentes.selected.unidad_medida}}
        </span>
              </div>
              <div style="margin-right: 10px;" *ngIf="row.agentes?.selected && row.usos?.selected?.label !== '-' && appName!=='agroleza'">
        <span style="
          position: relative;
          top: 5px">Plazo Seg. {{row.agentes.selected.plazo_seguridad}}</span>
              </div>
          </div>
          <br>
      </div>
  </div>
  <div>
      <div class=" d-flex justify-content-center">
          <button class="btn btn-dark mx-2" (click)="goBack()">Volver</button>
          <app-request-button
                  [request]="request"
                  [mode]="requestButtonType"
                  [lockOnSuccess]="true"
                  (click)="submit()">
          </app-request-button>
      </div>
  </div>
</p-panel>
<br>