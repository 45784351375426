import {ProductosModel} from '../../../../../models/form-common/productos.model';
import {Filtering} from '../../../../../service/filtering/filtering';
import {ProductosApiService} from '../../../../../service/api/productos-api';
import {ClientesApiService} from '../../../../../service/api/clientes-api.service';
import {StorageManager} from '../../../../../common/storage-manager.class';
import {Utils} from '../../../../../common/utils';
import { environment } from '../../../../../../environments/environment';

export class TratamientosFormInitData {

    public showTratamientosAllProductos = environment.features.showTratamientosAllProductos;

    public clientes: { selected: any; filtered: { label: any; value: any; }[]; } = {selected: null, filtered: []};
    public productos: { values: { label: string; value: ProductosModel; }[]; filtered: any; } = {values: [], filtered: []};
    public productosApi: ProductosApiService | undefined;
    public clientesApi: ClientesApiService | undefined;

    constructor() {}

    public start(
        clientes: { selected: any; filtered: { label: any; value: any; }[]; },
        productos: { values: { label: string; value: ProductosModel; }[]; filtered: any; },
        clientesApi: ClientesApiService | undefined,
        productosApi: ProductosApiService | undefined
    ): Promise<boolean> {
        this.clientes = clientes;
        this.productos = productos;
        this.clientesApi = clientesApi;
        this.productosApi = productosApi;

        return new Promise((resolve) => {
            this.getClientes().then(this.getProductos.bind(this)).then(resolve);
        });
    }

    private getClientes(): Promise<boolean> {
        return new Promise((resolve) => {
            this.clientesApi?.clientesGET.response((value: any[]) => {
                const user = StorageManager.getUser() || {} as any;

                this.clientes.selected = (value instanceof Array ? value : [])
                    .filter(it => it)
                    .find(it => it.id === (StorageManager.getClient() || {} as any).id);

                // if (value && user.rol !== 'admin') {
                if (value) {
                    if ( environment.features.showTareasMto ) {
                        this.clientes.filtered = value
                            .filter(it => it)
                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                            .map(it => ({label: it.nombre || it.razon_social, value: it}));

                        resolve(true);
                    } else {
                        this.clientes.filtered = value
                            .filter(it => it)
                            .filter(it => [it.id_usuario_asignado, it.id_usuario_asignado2].includes(user.id))
                            .sort((a, b) => a.nombre.localeCompare(b.nombre))
                            .map(it => ({label: it.nombre || it.razon_social, value: it}));

                        resolve(true);
                    }
                    
                }
                //  else {
                //     this.clientes.filtered = value
                //         .filter(it => it)
                //         .sort((a, b) => a.nombre.localeCompare(b.nombre))
                //         .map(it => ({label: it.nombre || it.razon_social, value: it}));

                //     resolve(true);
                // }
            });
            this.clientesApi?.clientesGET.safePerform();
        });
    }

    private getProductos(): Promise<boolean> {
        return new Promise((resolve) => {
            this.productosApi?.productos.GET.response((value: ProductosModel[]) => {
                if (value) {
                    this.productos.values = value
                        .filter(it =>
                            this.showTratamientosAllProductos ? it :  
                                (it.tipo === 'fitosanitario'  || it.tipo === 'fertilizante')
                        )
                        .map(it => {
                            it.nombre = (it.nombre || '').trim().toUpperCase();
                            return it;
                        })
                        .filter(
                            Filtering.distinctBy.bind({field: 'nombre'})
                        )
                        .map(it => {
                            const level = Utils.checkIfProductIsValid(it['fecha_caducidad'], it.tipo ?? '');
                            return ({label: level + ' ' + it.nombre, value: it});
                        });

                    this.productos.filtered = this.productos.values;

                    resolve(true);
                }
            });
            this.productosApi?.productos.GET.safePerform();
        });
    }

}
