import {Filtering} from '../../../../../service/filtering/filtering';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {list} from '../../../../../common/classes/request-builder';
import { environment } from '../../../../../../environments/environment';

import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';

export class TratamientosFormDefinition {
    public showAplicadoresActivo = environment.features.showAplicadoresActivo;

    public date: Date = new Date();

    public fincas: TypeAheadInterface = list();
    public clientes: TypeAheadInterface = list();
    public estado: TypeAheadInterface = list(['...', null], 'Activo', 'No Activo');

    public productRows: {
        productos: TypeAheadInterface;
        uds_medida_cuaderno?: string;
        uds_medida?: string;
        usos: TypeAheadInterface;
        usosFilter: Filtering<any>;
        agentes: TypeAheadInterface;
        agentesFilter: Filtering<any>;
        ocultar_en_receta?: any;
    }[] = [];

    public productos: TypeAheadInterface = list();
    public usos: TypeAheadInterface = list();

    public usosFilter: Filtering<any> = new Filtering<any>();
    public agentes: TypeAheadInterface = list();

    public agentesFilter: Filtering<any> = new Filtering<any>();

    public values: any = {
        nombre: '',
        fecha_act: '',
        cantidadReal: [],
        cantidadCuaderno: [],
        status: []
    };

    public formFields: ItemInterface<any>[] = [
        {field: 'nombre', label: 'Nombre', inputType: {type: InputType.EDIT_TEXT}},
        {label: 'Activo', field: 'activo', inputType: {type: InputType.SWITCH}, visible: this.showAplicadoresActivo},
    ];
}

interface TypeAheadInterface {
    selected: any;
    values: any[];
    filtered: any[];
}