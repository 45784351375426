import { HttpClient } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { TratamientosApiService } from '../../../../service/api/tratamientos-api.service';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { Router } from '@angular/router';
import { BaseView } from '../../../../view/base-view';
import { InputType } from '../../../../common/components/form-builder/form-builder.component';
import moment from 'moment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { RequestButtonTypes } from '../../../../common/components/request-button/request-button.component';
import { Filtering } from '../../../../service/filtering/filtering';
import { GenericService } from '../../../../service/generic/generic.service';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { GraficasService } from '../../../../service/api/graficas.service';
import { Utils } from '../../../../common/utils';
// @ts-ignore
import google from '@google/maps';
import { firstValueFrom } from 'rxjs';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';

declare let google: any;
@Component({
    selector: 'app-trata-manez',
    templateUrl: './trata-manez.component.html',
    styleUrls: ['./trata-manez.component.scss']
})
export class TrataManezComponent extends BaseView implements OnInit {
    @ViewChild('gmap', {read: false, static: false}) gmapElement: any;
    map: google.maps.Map = null;

    geocoder: google.maps.Geocoder = null;
    public currentUser = StorageManager.getUser();
    public filter: Filtering<any> = new Filtering<any>();
    public showAll: boolean = false;

    public ratio = StorageManager.getUser().ratio_ha || 1;  

    public getRequest: RequestHandler = this.tratamientosApi.tratamientosManez.GET;
    public deleteRequest: RequestHandler = this.tratamientosApi.tratamientosManez.DELETE;
    public deleteRequestButtonType: RequestButtonTypes = RequestButtonTypes.DELETE;

    public pathToForm = 'trata-manez-form';
    public menuItemId = 'tratamientos-manez';
    public unidad = (StorageManager.getUser() || {} as any).uds_medida || 'Hectárea';
    public usuario = StorageManager.getUser();

    public receta: any = {};
    public showReceta: boolean = false;
    public showEnviarCorreo = true;
    public isGeneratingCanvas: boolean = false;
    public showSpeed = true;
    public showPressure = true;
    public showFlow = true;
    public showDose = false;
    public updateMap = false;
    public showMapRoute = true;
    public showMapDirections = true;
    public intervaloRecorrido: any[] = [];

    public aux_data: any = null;
    public data: any = null;
    public verbose: any = null;

    public deleteWarning: string = '';
    public parcelasFormatted = '';

    public totalCaudales: any[] = [];

    public tablaCaudales: any = {
        hci: {
            index: ['h005', 'h0075', 'h01', 'h015', 'h02', 'h025', 'h03', 'h035', 'h04', 'h05'],
            3: [0.19, 0.3, 0.4, 0.6, 0.8, 1, 1, 2, 1.4, 1.6, 2],
            4: [0.22, 0.35, 0.46, 0.69, 0.92, 1.15, 1.39, 1.62, 1.85, 2.31],
            5: [0.25, 0.39, 0.52, 0.77, 1.03, 1.29, 1.55, 1.81, 2.07, 2.58],
            6: [0.27, 0.42, 0.57, 0.85, 1.13, 1.41, 1.7, 1.98, 2.26, 2.83],
            7: [0.29, 0.46, 0.61, 0.92, 1.22, 1.53, 1.83, 2.14, 2.44, 3.06],
            8: [0.31, 0.49, 0.65, 0.98, 1.31, 1.63, 1.96, 2.29, 2.61, 3.27],
            9: [0.33, 0.52, 0.69, 1.04, 1.39, 1.73, 2.08, 2.42, 2.77, 3.46],
            10: [0.35, 0.55, 0.73, 0.1, 1.46, 1.83, 2.19, 2.56, 2.92, 3.65],
            11: [0.36, 0.57, 0.77, 1.15, 1.53, 1.91, 2.3, 2.68, 3.06, 3.83],
            12: [0.38, 0.6, 0.8, 1.2, 1.6, 2, 2.4, 2.8, 3.2, 4],
            13: [0.4, 0.62, 0.83, 1.25, 1.67, 2.08, 2.5, 2.91, 3.33, 4.16],
            14: [0.41, 0.65, 0.86, 1.31, 1.73, 2.16, 2.59, 3.02, 3.46, 4.32],
            15: [0.42, 0.67, 0.89, 1.34, 1.79, 2.24, 2.68, 3.13, 3.58, 4.47],
            16: [0.44, 0.69, 0.92, 1.39, 1.85, 2.31, 2.77, 3.23, 3.7, 4.62],
            17: [0.45, 0.71, 0.95, 1.43, 1.9, 2.38, 2.86, 3.33, 3.81, 4.76],
            18: [0.47, 0.73, 0.98, 1.47, 1.96, 2.45, 2.94, 3.43, 3.92, 4.9],
            19: [0.48, 0.75, 1.01, 1.51, 2.01, 2.52, 3.02, 3.52, 4.03, 5.03],
            20: [0.49, 0.77, 1.03, 1.55, 1.55, 2.58, 3.1, 3.61, 4.13, 5.16]
        },
        // Valores referenciales. Se calculan en la función calcularTablaCaudal().
        amt: {
            index: ['a08', 'a1', 'a11', 'a12', 'a1212', 'a15', 'a1515', 'a18', 'a1818', 'a2', 'a22'],
            3: [0.45, 0.50, 0.88, 0.56, 1.16, 0.77, 1.93, 1.24, 2.33, 1.05, 2.30],
            4: [0.52, 0.58, 0.95, 0.67, 1.28, 0.89, 2.15, 1.40, 2.66, 1.22, 2.71],
            5: [0.60, 0.65, 1.02, 0.78, 1.40, 1.02, 2.37, 1.56, 3, 1.40, 3.12],
            6: [0.68, 0.72, 1.09, 0.89, 1.52, 1.15, 2.59, 1.72, 3.34, 1.58, 3.53],
            7: [0.75, 0.80, 1.16, 1, 1.64, 1.27, 2.81, 1.88, 3.67, 1.75, 3.94],
            8: [0.83, 0.87, 1.24, 1.10, 1.76, 1.40, 3.04, 2.04, 4.01, 1.93, 4.34],
            9: [0.90, 0.95, 1.31, 1.21, 1.88, 1.52, 3.26, 2.20, 4.34, 2.10, 4.75],
            10: [0.98, 1.02, 1.38, 1.32, 2, 1.65, 3.48, 2.36, 4.68, 2.28, 5.16],
            11: [1, 1.05, 1.45, 1.36, 2.10, 1.70, 3.65, 2.50, 4.92, 2.41, 5.36],
            12: [1.02, 1.08, 1.52, 1.40, 2.19, 1.76, 3.82, 2.63, 5.16, 2.54, 5.57],
            13: [1.04, 1.12, 1.58, 1.45, 2.29, 1.81, 3.98, 2.77, 5.40, 2.68, 5.77],
            14: [1.06, 1.15, 1.65, 1.49, 2.38, 1.87, 4.15, 2.90, 5.64, 2.81, 5.98],
            15: [1.08, 1.18, 1.72, 1.53, 2.48, 1.92, 4.32, 3.04, 5.88, 2.94, 6.18],
            16: [1.12, 1.22, 1.77, 1.60, 2.56, 2.02, 4.49, 3.10, 6.10, 3.04, 6.42],
            17: [1.15, 1.27, 1.82, 1.66, 2.64, 2.11, 4.66, 3.17, 6.31, 3.13, 6.66],
            18: [1.19, 1.31, 1.86, 1.73, 2.72, 2.21, 4.82, 3.23, 6.53, 3.23, 6.90],
            19: [1.22, 1.36, 1.91, 1.79, 2.80, 2.30, 4.99, 3.30, 6.74, 3.32, 7.14],
            20: [1.26, 1.40, 1.96, 1.86, 2.88, 2.40, 5.16, 3.36, 6.96, 3.42, 7.38]
        }
    };

    public cols = [
        { field: 'n_tratamiento', header: 'Nº\xa0Trat.', filter: '', width: '80px' },
        { field: 'nombre', header: 'Nombre', filter: '' },
        { field: 'campo_nombre', header: 'Campo', filter: '' },
        { field: 'maquinaria_nombre', header: 'Maquinaria', filter: '' },
        { field: 'fecha_formatted', header: 'F.\xa0Receta', filter: '' },
        { field: 'litros', header: 'Litros/' + this.unidad, filter: '' },
        { field: 'marcha', header: 'Marcha (km/h)', filter: '' },
        { field: 'caudal', header: 'Caudal (l/min)', filter: '' },
        { header: 'Usuario', field: 'usuario_nombre', visible: this.currentUser.rol === 'admin' },
    ];

    public formFields: ItemInterface<any>[] = [
        { field: 'n_tratamiento', label: 'Nº\xa0Tratamiento', inputType: { type: InputType.TEXT } },
        { field: 'nombre', label: 'Nombre', inputType: { type: InputType.TEXT } },
        { field: '', inputType: { type: InputType.EMPTY } },
        { field: 'maquinaria_nombre', label: 'Maquinaria', inputType: { type: InputType.TEXT } },
        { field: 'campo_nombre', label: 'Campo', inputType: { type: InputType.TEXT } },
        { field: 'ancho_calle', label: 'Ancho de calle', inputType: { type: InputType.TEXT } },
        { field: 'fecha_formatted', label: 'Fecha Receta', inputType: { type: InputType.TEXT } },
        { field: 'hora_inicio', label: 'Hora Inicio', inputType: { type: InputType.TEXT } },
        { field: 'hora_fin', label: 'Hora Fin', inputType: { type: InputType.TEXT } },
        { field: 'litros', label: 'Litros/' + this.unidad, inputType: { type: InputType.TEXT } },
        { field: 'marcha', label: 'Velocidad de avance (km/h)', inputType: { type: InputType.TEXT } },
        { field: 'rpm', label: 'RPM', inputType: { type: InputType.TEXT } },
        { field: 'presion', label: 'Presión', inputType: { type: InputType.TEXT } },
        { field: 'caudal', label: 'Caudal (l/min)', inputType: { type: InputType.TEXT } },
        { field: 'modo_trabajo', label: 'Modo trabajo', inputType: { type: InputType.TEXT } },
        { field: 'regulacion_dosis', label: 'Regulacion_dosis', inputType: { type: InputType.TEXT } },
        { field: 'estado_tratamiento', label: 'Estado tratamiento', inputType: { type: InputType.TEXT } },
        { field: 'boquilla_nombre', label: 'Boquilla', inputType: { type: InputType.TEXT } }
    ];


    public title_modal = '';

    public grafica_flow: any = [];
    public grafica_pressure: any = [];
    public grafica_vel: any = [];
    public grafica_dose: any = [];
    public grafica_rpm: any = [];
    public tracks: any = [];
    public tracks_empty: any = [];
    public tracksRoute: any = [];
    public showGraficas = false;
    public showMap = true;
    public data_dose_user: {id_usuario: string; ratio_ha: string; uds_medida: string } = {
        id_usuario: '',
        ratio_ha: '',
        uds_medida: '',
    };
    public maquinaria_imei = '';
    fecha_tratamiento = '';

    constructor(public tratamientosApi: TratamientosApiService,
        private resumenService: GenericService,
        private graficaService: GraficasService,
        private dashboardEvents: DashboardService,
        private router: Router,
        private http: HttpClient
    ) {
        super(dashboardEvents, tratamientosApi.tratamientosManez.GET, tratamientosApi.tratamientosManez.DELETE);
    }

    ngOnInit() {
        this.showAll = Utils.initShowAll('showAllmanez_tratamientos') === true && StorageManager.getUser().rol === 'admin' ? true : false;
        this.initFilter();
        this.dashboardEvents.setMenuItem('tratamientos-manez');
    }

    initializeMap() {
        this.map = [];
    
        window.scrollTo(0, 0);
        window.scrollBy(0, 0);
        window.scroll(0, 0);
    }

    hiddenShowSpeed( ) {
        this.showSpeed = !this.showSpeed;
    }

    hiddenShowPressure( ) {
        this.showPressure = !this.showPressure;
    }

    hiddenShowFlow( ) {
        this.showFlow = !this.showFlow;
    }

    hiddenShowDose( ) {
        this.showDose = !this.showDose;
    }

    hiddenShowSectionRoute( ) {
        this.showMapRoute = !this.showMapRoute;
    }

    routeReDraw() {

        const data = this.data; 
        const verbose = this.verbose; 
        if ( this.updateMap ) {
            this.deleteInterval();
            this.updateMap = false;
            return;
        }
        this.updateMap = true;
        this.data_dose_user.id_usuario = data.id_usuario;
        this.data_dose_user.ratio_ha = data.ratio_ha;
        this.data_dose_user.uds_medida = data.uds_medida; 
        const nTratamiento = data.n_tratamiento;
        const idMaquinaria = data.maquinaria.id;
        const imei = data.maquinaria.imei;
        const idTratamiento = data.id;
        this.title_modal = 'Resumen Sistronic';
        this.aux_data = null;

        this.showMap = true;
        this.intervaloRecorrido.push(setInterval( () => {
            this.resumenService.resumeTronic(nTratamiento, imei).toPromise()
                .then((resume: any) => {
      
                    if (resume.data.length > 0) {
                        this.showGraficas = true;
                        setTimeout(() => {
                            this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                                zoom: 10,
                                //center: new google.maps.LatLng(this.lat, lng),
                                zoomControl: true,
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.RIGHT_TOP
                                },
                                mapTypeId: google.maps.MapTypeId.HYBRID,
                                mapTypeControlOptions: {
                                    mapTypeIds: ['roadmap', 'hybrid'],
                                    position: google.maps.ControlPosition.TOP_RIGHT,
                                    labels: true
                                },
                                disableDefaultUI: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                tilt: 0 // para que no se muestre el mapa con 45º
              
                            });


                            resume = resume.data[0];
                            resume.dose = resume.dose === '0' ? '' : resume.dose;
                            this.receta['end_litros'] = this.calculateLiteHectarea( resume.liters, data.ratio_ha);
                            this.receta['end_marcha'] = resume.vel;
                            this.receta['end_rpm'] = resume.rpm;
                            this.receta['end_presion'] = resume.pressure;
           
                            if (verbose) {
           
                                firstValueFrom(
                                    this.graficaService.getManezGraficas(
                                        idTratamiento, 
                                        nTratamiento, 
                                        idMaquinaria, 
                                        this.fecha_tratamiento
                                    )
                                ).then(() => {
                                    this.getTracksMyL(nTratamiento, imei);
                                    this.showDose = true;
                                });
              
              
                            }
  
                        } , 500);
                    } else {
                        if (verbose) {  
                            if (this.updateMap === false) {
                                alert('Tratamiento pendiente de finalizar.');
                            } 
          
                        }
                    }
                    this.initializeMap();
                }).catch(error => {
                    console.log('error', error);
                }
                );
        }, 5000));
    
  

    }

    calculateLiteHectarea( liters: number , ratio_ha: number ) {
        
        if ( ratio_ha == null || liters == null ){
            return 0;
        }

        if ( ratio_ha === 0 || liters === 0 ) {
            return 0;
        }

        else {
            const resultado = ((ratio_ha / liters) * 10000).toFixed(2);
            return resultado;
        }
    }

    deleteInterval(){
        for (let index = 0; index < this.intervaloRecorrido.length; index++) {
            const interval = this.intervaloRecorrido[index];
            clearInterval(interval);
        }
    }

    splitProductosUnidades( value: string ) {
        const productoUnidades = value.split(' ');
        return this.decimalFormat(productoUnidades[0], 2) + '' + productoUnidades[1] ? productoUnidades[1] : '';
    }

    fillChartDecimals( chart: {datasets: {data: string[] }}, decimals: number | undefined) {
        return new Promise( resolve => {
            for (let index = 0; index < chart.datasets.data.length; index++) {
                const data = chart.datasets.data[index];
                if ( data == null || data.length === 0){
                    chart.datasets.data[index] = '0';
                } else {
                    chart.datasets.data[index] = parseFloat(data).toFixed(decimals);
        
                }
                if ( (index + 1 ) >= chart.datasets.data.length ) {
                    resolve(chart);
                }
            }
        });
      
    }

    public changeShowAll() {
        sessionStorage.setItem('showAllmanez_tratamientos', this.showAll === true ? 'true' : 'false');
    }

    public add() {
        this.router.navigate(['dashboard', 'crear-tratamiento-manez']);
    }
    public edit(data: any) {
        this.router.navigate(['dashboard', 'editar-tratamiento-manez', data.id]);
    }
    public duplicate(data: any) {
        this.router.navigate(['dashboard', 'duplicar-tratamiento-manez', data.id]);
    }

    public override deleteRegister() {
        if (this.model['id']) {
            this.deleteWarning = '';

            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
                if (!this.deleteRequest.hasError) {
                    this.getRequest.safePerform();
                }
            });
        } else {
            this.deleteRequest.hasError = true;
            throw new Error('Trying to DELETE a register without primary key');
        }
    }

    public globalVisualTransform(value: string, col: { field: string; }): any {
    
    
        if (Utils.isNumber(value)) {
      
            if ( col.field === 'n_tratamiento') {
                return value;
            }

            return Utils.decimalFormat(value.replace(',', '.'), 2, '.', ',', 3);
        }


    // if ( columnas.includes( (col.field as string ).toLowerCase() )  ){
    //   return Utils.decimalFormat(value, 2, '.', ',', 3);
    // }
    }

    public globalConditionalStyle(_value: any, _col: any, _row: any): any {
    }

    public async loadResumeTronic(data: any, verbose: boolean = true) {
        this.initializeMap();
        this.updateMap = false;
        this.data = data;
   
        this.verbose = verbose;
        this.showGraficas = false;
        this.showMap = false;
        this.data_dose_user.id_usuario = data.id_usuario;
        this.data_dose_user.ratio_ha = data.ratio_ha;
        this.data_dose_user.uds_medida = data.uds_medida;
        // let fecha_formatted = (data.fecha_formatted as string).replace(/\//g,'-');

        const nTratamiento = data.n_tratamiento;

        if (!data.maquinaria && verbose) {
            alert('Tratamiento sin maquinaria asignada');
            return;
        }

        this.maquinaria_imei = data.maquinaria.imei ? data.maquinaria.imei : '';
        if (this.maquinaria_imei === '' || this.maquinaria_imei.length === 0) {
            alert('Maquinaria sin imei');
            return;
        }

        const idMaquinaria = data.maquinaria.id;
        const imei = data.maquinaria.imei;
        const idTratamiento = data.id;
        this.title_modal = 'Resumen Sistronic';
        let auxData: any = null;
        this.aux_data = null;
    
        if ( imei == null || imei.length === 0 ) {
            alert('Maquinaria sin imei asignado');
            return;
        }

        let fecha = (data.fecha_formatted as string).replace(/\//g, '-');
        const tratamientosDatos: any = await firstValueFrom(this.http.get(
            'https://appmyl.com/ws/tratamientos/tratamiento_fecha_imei.php?fecha=' + fecha + 
          '&imei=' + imei + 
          '&t_id=' + nTratamiento 
        ));
        const fechaTratamiento = tratamientosDatos['fecha'];
        this.fecha_tratamiento = fechaTratamiento;
        //console.log('tratamientos_datos', tratamientos_datos);
        this.showMap = true;
        this.resumenService.resumeTronic(nTratamiento, this.maquinaria_imei).toPromise()
            .then( (resume: any) => {
        
                if (resume.data.length > 0) {       
                    if ( verbose ) {
                        this.showGraficas = true;
                    }
          
          
                    setTimeout(async () => {
                        if ( verbose  && this.gmapElement) {
                            this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                                zoom: 10,
                                //center: new google.maps.LatLng(this.lat, lng),
                                zoomControl: true,
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.RIGHT_TOP
                                },
                                mapTypeId: google.maps.MapTypeId.HYBRID,
                                mapTypeControlOptions: {
                                    mapTypeIds: ['roadmap', 'hybrid'],
                                    position: google.maps.ControlPosition.TOP_RIGHT,
                                    labels: true
                                },
                                disableDefaultUI: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                tilt: 0 // para que no se muestre el mapa con 45º
                
                            });
                        }else{
                            this.showMap = false;
                        }
         
                        auxData = JSON.parse(JSON.stringify(data));

                        resume = resume.data[0];
                        resume.dose = resume.dose === '0' ? '' : resume.dose;
                        this.receta['end_litros'] = this.calculateLiteHectarea( resume.liters, data.ratio_ha);
                        this.receta['end_marcha'] = resume.vel;
                        this.receta['end_rpm'] = resume.rpm;
                        this.receta['end_presion'] = resume.pressure;
                        auxData.rpm = resume.rpm || '';
                        auxData.presion = resume.pressure || '';
                        auxData.presion = this.decimalFormat(auxData.presion, 2);
                        auxData.marcha = resume.vel || '';
                        auxData.marcha = this.decimalFormat(auxData.marcha, 2);
                        auxData.litros = resume.liters || '';
                        auxData.regulacion_dosis = resume.dose || '';
                        auxData.estado_tratamiento = 'Finalizado';
                        auxData.linea = [];
                        auxData.cantidades_list = (auxData.cantidades_real || '').split(';');
                        auxData.productos_list = auxData.productos.filter((it: any) => it).map((it: { nombre: any; }) => it.nombre);
                        auxData.productos_uds = auxData.productos.filter((it: any) => it).map((it: { uds_medida: any; }) => it.uds_medida);
                        if (verbose) {
                            //super.show(null, aux_data);
                            this.grafica_flow = null;
                            this.grafica_pressure = null;
                            this.grafica_vel = null;
                            this.showDose = false;
                            firstValueFrom(this.graficaService.getManezGraficas(
                                idTratamiento, 
                                nTratamiento, 
                                idMaquinaria, 
                                fechaTratamiento
                            )).then(async (grafica: any) => {
                  
                                if ( 
                                    grafica.flow.datasets.data.length === 0 || 
                                    grafica.pressure.datasets.data.length === 0 || 
                                    grafica.vel.datasets.data.length === 0   
                                ){
                                    this.showGraficas = false;
                                    return alert('Registro sin resultados');
                                }
  
                                this.grafica_flow = await this.fillChartDecimals(grafica.flow, 0);
                                this.grafica_pressure = await this.fillChartDecimals(grafica.pressure, 0);
                                this.grafica_vel =  await this.fillChartDecimals(grafica.vel, 1);
                                this.grafica_dose = await this.fillChartDecimals(grafica.dose, 0);
                                this.grafica_rpm = await this.fillChartDecimals(grafica.rpm, 0);
                                fecha = (data.fecha_formatted as string).replace(/\//g, '-');
                                this.getTracksMyL(nTratamiento, imei, fechaTratamiento);
                                this.showDose = true;
                            });
              
              
                        }
   
                    } , 500);
                } else {
         
          
                    setTimeout(() => {
   
                        if (verbose) {
                            //super.show(null, aux_data);
                            this.grafica_flow = null;
                            this.grafica_pressure = null;
                            this.grafica_vel = null;
                            this.showDose = false;
                            firstValueFrom(this.graficaService.getManezGraficas(
                                idTratamiento, 
                                nTratamiento, 
                                idMaquinaria, 
                                fechaTratamiento)).then((grafica: any) => {
                  
                                if ( 
                                    grafica.flow.datasets.data.length === 0 || 
                                    grafica.pressure.datasets.data.length === 0 || 
                                    grafica.vel.datasets.data.length === 0   
                                ){
                                    this.showGraficas = false;
                                    return alert('Registro sin resultados');
                                }

                                this.showGraficas = true;
                                setTimeout( async () => {
                                    this.map = new google.maps.Map(this.gmapElement.nativeElement, {
                                        zoom: 10,
                                        //center: new google.maps.LatLng(this.lat, lng),
                                        zoomControl: true,
                                        zoomControlOptions: {
                                            position: google.maps.ControlPosition.RIGHT_TOP
                                        },
                                        mapTypeId: google.maps.MapTypeId.HYBRID,
                                        mapTypeControlOptions: {
                                            mapTypeIds: ['roadmap', 'hybrid'],
                                            position: google.maps.ControlPosition.TOP_RIGHT,
                                            labels: true
                                        },
                                        disableDefaultUI: false,
                                        fullscreenControl: false,
                                        streetViewControl: false,
                                        tilt: 0 // para que no se muestre el mapa con 45º
                      
                                    });
                    
                                    this.grafica_flow = await this.fillChartDecimals(grafica.flow, 0);
                                    this.grafica_pressure = await this.fillChartDecimals(grafica.pressure, 0);
                                    this.grafica_vel =  await this.fillChartDecimals(grafica.vel, 1);
                                    this.grafica_dose = await this.fillChartDecimals(grafica.dose, 0);
                                    this.grafica_rpm = await this.fillChartDecimals(grafica.rpm, 0);
                    
                                    this.getTracksMyL(nTratamiento, imei, fechaTratamiento);
                                    this.showDose = true;
                                }, 500);
                  
                            });
              
              
                        }
  
                    } , 0);
                    // if (verbose) { alert('Tratamiento pendiente de finalizar.'); }
                }
            }).catch(error => {
                console.log('error', error);
            }
            );
        //this.initializeMap();

    }




    public override show(modal: string, data: any) {

        this.title_modal = 'Detalle del registro';
        // console.log('data', data);
        const auxData: any = {...data};
        super.show(modal, auxData);
        auxData.linea = [];
        auxData.litros = this.decimalFormat(auxData.litros, 0);
        auxData.caudal = this.decimalFormat(auxData.caudal, 0);
        auxData.rpm = this.decimalFormat(auxData.rpm, 0); 
        auxData.cantidades_list = (auxData.cantidades_real || '').split(';');
        auxData.productos_list = auxData.productos.filter((it: any) => it).map((it: { nombre: any; }) => it.nombre);
        auxData.productos_uds = auxData.productos.filter((it: any) => it).map((it: { uds_medida: any; }) => it.uds_medida);
    }

    public generarReceta(data: any) {
        this.showGraficas = false;
        this.receta = data;
        this.receta.tratamiento_nombre = data.nombre;
        this.receta.finca = data.campo_nombre;
        this.receta.usuario_email = data.usuario_email;
    
        this.receta.fecha = moment(this.receta.fecha_inicio, 'YYYY-MM-DD').format('LL');

        const dosis: string[] = (this.receta.cantidades_real || '').split(';');
        // console.log('this.receta', this.receta);
    
        (this.receta.productos as any[]).filter(it => it).map((it, i) => {
            it.dosis = this.decimalFormat( dosis[i] , 2);
            it.total = this.decimalFormat( 
                ((this.receta.litros || 0) * (it.dosis || 0) * (this.receta.campo.superficie_cultivada || 0) / 100) ,
                2
            );
            it.dosisTotal = 
              it.dosis ? 
                  this.decimalFormat( ((it.dosis || 0) * (this.receta.maquinaria.capacidad || 0) / 100), 2) : 
                  '0';
            it.dosisTotalProducto = this.decimalFormat((it.total || 0) / (this.receta.campo.superficie_cultivada || 1), 2);
            it.total = this.decimalFormat(it.total, 2);
            it.dosisTotal = this.decimalFormat(it.dosisTotal, 2);
            it.dosis = this.decimalFormat(it.dosis, 2);
            it.dosisTotalProducto = this.decimalFormat(it.dosisTotalProducto, 2);
            return it;
        });

        if (this.receta.productos.length === 0) {
            this.receta.productos.push({
                nombre: 'Sin productos',
                materia_activa: '-',
                dosis: '-'
            });
        }

        // Calculamos el tiempo del tratamiento en función del caudal y los litros totales.
        if (this.receta.caudal && this.receta.campo) {
            this.receta.litros_totales = (this.receta.litros || 0) * (this.receta.campo.superficie_cultivada || 0);
            this.receta.litros_totales = this.decimalFormat(this.receta.litros_totales, 0);

            if (this.receta.caudal === 0 || this.receta.caudal == null || this.receta.caudal === '') {this.receta.tiempo = 0; }
            else {
                this.receta.tiempo = parseFloat(this.receta.litros_totales) / parseFloat(this.receta.caudal) * 60;
                const horas = Number((this.receta.tiempo / 3600).toFixed(0));
                const minutos = Number(((this.receta.tiempo - horas * 3600) / 60).toFixed(0));
                const segundos = Number((this.receta.tiempo - (horas * 3600 + minutos * 60)).toFixed(0));
                this.receta.tiempo = horas + ':' + minutos + ':' + segundos;

                //this.receta.tiempo = new Date(this.receta.tiempo * 1000).toISOString().substr(11, 8);
            }
        }

        // Obtenemos los valores de la tabla de Presión y Caudal de la Boquilla seleccioanda.
        if (this.receta.boquilla) {
            if (typeof this.receta.boquilla.hci === 'string') {
                this.receta.boquilla.hci = JSON.parse(this.receta.boquilla.hci || '{}');
            }
            if (typeof this.receta.boquilla.amt === 'string') {
                this.receta.boquilla.amt = JSON.parse(this.receta.boquilla.amt || '{}');
            }
            this.calcularTablaCaudal();
            for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
                this.getCaudal(i);
            }
        }

        // Añadir los campos reales del tronic

        this.aux_data = null;

        this.loadResumeTronic('', this.receta);

        const intervalo = setInterval(() => {
            if (this.aux_data != null) {
                clearInterval(intervalo);
            }
        }, 1000);

        this.showReceta = true;

        const elements = document.getElementsByClassName('ui-dialog-content ui-widget-content');
        if (elements && elements[0]) {
            setTimeout(() => {
                if (elements[0]) {
                    elements[0].scrollTop = 0;
                }
            }, 100);
        }

    }

    public getCaudal(tableIndex: number) {
        let total = 0;

        for (const prop in this.receta.boquilla.hci) {
            if (this.receta.boquilla.hci[prop]) {
                const idx = this.tablaCaudales.hci.index.indexOf(prop);
                total += this.tablaCaudales.hci[tableIndex][idx] * this.receta.boquilla.hci[prop];
            }
        }

        for (const prop in this.receta.boquilla.amt) {
            if (this.receta.boquilla.amt[prop]) {
                const idx = this.tablaCaudales.amt.index.indexOf(prop);
                total += this.tablaCaudales.amt[tableIndex][idx] * this.receta.boquilla.amt[prop];
            }
        }

        this.totalCaudales[tableIndex] = total.toFixed(2);
        this.totalCaudales[tableIndex] = this.decimalFormat(this.totalCaudales[tableIndex], 2);
    }

    public imprimirReceta() {
        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLCanvasElement, { scale: 2 }).then(canvas => {
            const downloadReceta: any = document.getElementById('downloadReceta');
            downloadReceta.href = canvas.toDataURL('image/png');
            downloadReceta.download = 'Receta';
            downloadReceta.click();
        });
    }

    public getPrintConfig() {
        const config: {canvasScale: number; orientation: 'p'|'l'; width: number; height: number} = {
            canvasScale: 2,
            orientation: 'p',
            width: 210,
            height: 290
        };
        // Si se está mostrando el documento Global GAP en vez de la receta normal, no se cambia la configuración.
        // if (this.isShowingGlobalGAP) {
        //     return {
        //         canvasScale: 2,
        //         orientation: 'l',
        //         width: 210,
        //         height: 290
        //     };
        // }

        return config;
    }

    public enviarCorreo() {
        this.isGeneratingCanvas = true;
        const config = this.getPrintConfig();

        html2canvas(document.getElementById('html2canvasDiv') ?? new HTMLCanvasElement, { scale: config.canvasScale, logging: false }).then(canvas => {
            this.isGeneratingCanvas = false;
            const pdf = new jsPDF(config.orientation, 'mm', 'a4');
            const data = canvas.toDataURL('image/png');

            if (canvas.width > canvas.height) {
                pdf.addImage(data, 'png', 0, 0, config.width, (canvas.height / (canvas.width / config.width)), 'alias', 'FAST');
            } else {
                pdf.addImage(data, 'png', 0, 0, (canvas.width / (canvas.height / config.height)), config.height, 'alias', 'FAST');
            }

            const adjunto = encodeURIComponent(window.btoa(pdf.output()));

            const email = this.receta.usuario_email || '';

            if (email !== '') {
                this.tratamientosApi.enviarRecetaCaptura.perform({
                    email,
                    generado: adjunto,
                    finca: this.receta.finca,
                    tratamiento: this.receta.tratamiento_nombre,
                });
            } else {
                this.tratamientosApi.enviarRecetaCaptura.hasError = true;
                this.tratamientosApi.enviarRecetaCaptura['error'] = 'El usuario no tiene un email asociado';
            }
        }).then(() => {
            // console.log('Adjuntos generados.');
        }, () => {
            alert('Ha habido un error al enviar el email. Si este error persiste, por favor, contacte con un administrador.');
            // console.log(error);

            this.isGeneratingCanvas = false;
            this.tratamientosApi.enviarRecetaCaptura.hasError = true;
            this.tratamientosApi.enviarRecetaCaptura['error'] = 'Error al enviar email';
        });
    }

    private initFilter() {
        this.filter.addFilter((data: any) => (data || [])
            .filter((it: any) => it)
            .filter((it: { id_usuario: any; }) => !this.showAll
                ? [it.id_usuario].includes(this.currentUser.id)
                : true)
        );
    }

    private calcularTablaCaudal() {
        let D10: number, D15: number, D20: number, D25: number;

        for (const i of [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]) {
            for (const j of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]) {
                D10 = this.tablaCaudales.amt[5][j] ?? 0;
                D15 = this.tablaCaudales.amt[10][j] ?? 0;
                D20 = this.tablaCaudales.amt[15][j] ?? 0;
                D25 = this.tablaCaudales.amt[20][j] ?? 0;

                if (i < 5) {
                    this.tablaCaudales.amt[i][j] = D10 - (((D15 - D10) / 5) * (5 - i));
                } else if (i < 10) {
                    this.tablaCaudales.amt[i][j] = D10 + (((D15 - D10) / 5) * (i - 5));
                } else if (i < 15) {
                    this.tablaCaudales.amt[i][j] = D15 + (((D20 - D15) / 5) * (i - 10));
                } else if (i < 20) {
                    this.tablaCaudales.amt[i][j] = D20 + (((D25 - D20) / 5) * (i - 15));
                }
            }
        }

    }

    ///////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////  PRUEBAS RECORRIDO GPS MYL  /////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    private getTracksMyL( id_tratamiento: string, imei: string, fecha?: string ) {

        /*
          let url = 
            'https://locatec.es/proyectos/myl/ws/tracks_myl/getTracksMyL.php?fecha_ini=03/04/2020&fecha_fin=05/04/2020'; //campo quique
          let url = 'https://locatec.es/proyectos/myl/ws/tracks_myl/getTracksMyL.php?fecha_ini=05/06/2020&fecha_fin=06/06/2020';  //misana
          let url = 'https://locatec.es/proyectos/myl/ws/tracks_myl/getTracksMyL.php?limit=100&user=ricartmisana';  //misana
        */
        let url = 
          'https://appmyl.com/ws/index.php?p1=tratamientos&p2=get_coord_dismuntel&n_tratamiento=' + id_tratamiento + '&imei=' + imei;
        if ( fecha != null ) {
            url = 
              'https://appmyl.com/ws/index.php?p1=tratamientos&p2=get_coord_dismuntel&n_tratamiento=' + id_tratamiento + 
                '&imei=' + imei + '&fecha=' + fecha;  //DISMUNTEL
        }


        this.http.get(url).subscribe( (data: any ) => {
      
            if ( data.data.length === 0) {
                alert('No hay ruta para este Tratamiento');
                this.showGraficas = false;
                return false;
            } else { 
                this.showGraficas = true;
                this.drawTracks(data);
                return true;
            }
      
            // console.log(data);
        });
    }

    private drawTracks(data: { data: any[]; }) {

        let res: any = 0;    
        let opacity: any = 0;    

        this.tracks = [];    
        this.tracks_empty = [];
        let oneTrack: any = [];
        let lat: any = '';
        let lon: any = '';
        let latAnt: any = '';
        let lonAnt: any = '';

        let color: any = '';
        this.tracksRoute  = data.data;
        data.data.forEach((track) => {
            //console.log("track: ", track);      
            lat = track.lat;
            lon = track.lon;
          
            if (latAnt === '') {
                latAnt = lat;
                lonAnt = lon;
            }

            //this.tracks.push(new google.maps.LatLng(track.lat, track.lon));

            // eslint-disable-next-line no-bitwise
            res = track.io & 15;

            color = res === 0 ? 'yellow' : '#ff3333';
            opacity = res === 0 ? 0.8 : 1;


            oneTrack = new google.maps.Polyline({
                path: [new google.maps.LatLng(latAnt, lonAnt), new google.maps.LatLng(track.lat, track.lon)],
                strokeColor: color,
                geodesic: true,
                strokeOpacity: opacity,
                strokeWeight: 5
            });

            oneTrack.setMap(this.map); 

            latAnt = lat;
            lonAnt = lon;
        });
    
        //console.log("Tracks: ", this.tracks);

        /*
    this.tracks = new google.maps.Polyline({
      path: this.tracks,
      strokeColor: color,
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 5,
    });
   */
        // this.tracks[this.tracks.length].track.lat this.tracks[this.tracks.length].track.lon
        if (this.gmapElement) { 
            this.map.setCenter(new google.maps.LatLng(lat , lon));
            this.map.setZoom(17);
        }
        //this.tracks.setMap(this.map);

    }
}


/**
 * 
  private drawTracks(data) {

    this.tracks = [];
    let lat: any = '';
    let lon: any = '';
    this.tracks = new google.maps.Polyline({
      path: null,
      strokeColor: '#ff3333',
      geodesic: true,
      strokeOpacity: 1,
      strokeWeight: 5,
    });
    data.data.forEach((track) => {
      this.tracks.push(new google.maps.LatLng(track.lat, track.lon));
      lat = track.lat;
      lon = track.lon;
    });
    let tracksaux = [...this.tracks];
    //console.log("Traks: "+tracks);
    let aux_tracks: any[] = [];
    for (let index = 0; index < tracksaux.length; index++) {
     let timeOut = setTimeout(() =>{
      
        aux_tracks.push (tracksaux[index]);
        this.tracks = new google.maps.Polyline({
          path: aux_tracks,
          strokeColor: '#ff3333',
          geodesic: true,
          strokeOpacity: 1,
          strokeWeight: 5,
        });
        if (index == 0 ) {
          this.map.setCenter(new google.maps.LatLng(lat , lon));
          this.map.setZoom(17);
          
        }
        this.tracks.setMap(this.map);
        if (this.showGraficas == false ){
          clearTimeout(timeOut);
        }
      } , 100 * index )

      
     
    }

  
   
    // this.tracks[this.tracks.length].track.lat this.tracks[this.tracks.length].track.lon
 
  }

 */