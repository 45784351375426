<br>
<p-dialog header="Eliminar Cita" [(visible)]="showEliminaCita" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
        ¿Seguro que desea eliminar esta cita?
    </div>
    <div *ngIf="citaSeleccionada?.visita?.id" class="container" style="max-width:480px; width: auto; overflow: auto">
        <p style="color: red;">También se eliminará la visita realizada!</p>        
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEliminaCita = false;">No</button>
        <button class="btn btn-danger" style="margin: 10px;" (click)="eliminaCita();">Sí</button>
    </div>
</p-dialog>    

<p-dialog *ngIf="citaSeleccionada?.id" [header]="showHorasVisitas ? 'Fecha y Hora' : 'Fecha'" [(visible)]="showEditCita" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
    <p-calendar #citaCalendar
        [(ngModel)]=date
        [stepMinute]=30
        [inline]="true"
        [showTime]="showHorasVisitas ? true : false">
    </p-calendar>
    </div>
    <div class="container" style="text-align:center; max-width:480px; width: auto; overflow: auto">
        <button class="btn btn-secondary" style="margin: 10px;" (click)="showEditCita = false;">Volver</button>
        <button class="btn btn-success" style="margin: 10px;" (click)="guardaCita(citaCalendar);">Guardar</button>
    </div>
</p-dialog>

<p-dialog *ngIf="citaSeleccionada?.id" header="Subir fotos" [(visible)]="showUploadPhotos" (onShow)="scrollToTop()">
    <div class="container" style="max-width:480px; width: auto; overflow: auto">
        <p-fileUpload
            #uploadPhotos
            name="files[]"
            multiple="multiple"
            accept="image/*"
            chooseLabel="Buscar..."
            uploadLabel="Subir"
            cancelLabel="Cancelar"
            maxfilesize="500000"
            [url]="serverUrl + 'ws/visitas/upload.php?id=' + citaSeleccionada?.visita?.id"
            (onBeforeUpload)="onBeforeUpload()"
            (onUpload)="onUpload()">
            <ng-template pTemplate="toolbar">
                <div>Puede subir un máximo de 20 fotos.</div>
                <div *ngIf="imagenesVisita && imagenesVisita[0]"
                    class="alert alert-warning w-100 p-2 my-2">
                    <b>Aviso:</b> se borrarán las fotos anteriores.
                </div>
            </ng-template>
            <ng-template let-file pTemplate="file">
                <div>{{file?.name}}</div>
            </ng-template>
        </p-fileUpload>
    </div>

</p-dialog>

<p-dialog header="Subir Documento" [(visible)]="showDialogUploadFile" [responsive]="true" [style]="{width: 'width', minWidth: 'minWidth'}">
    <p-fileUpload name="documents[]" [url]="serverUrl + 'ws/visitas/uploadDocument.php?id=' + citaSeleccionada?.visita?.id" [multiple]="false"
    maxFileSize="" chooseLabel="Seleccionar" uploadLabel="Subir" cancelLabel="Cancelar" (onUpload)="getStatusUploadDocument($event)" 
    (onSelect)="checkTypeDocument($event)"  [showUploadButton]="!showErrorTypeDocument" [showCancelButton]="false"
    (onRemove)="onClearUploadDocument($event)" [disabled]="showErrorTypeDocument">
        <ng-template #name pTemplate="toolbar">
            <div class="alert alert-warning w-100 p-2 my-2">
                <b>Aviso:</b> se borrarán los archivos anteriores. Y solo se archivos tipo PDF
            </div>
            <div *ngIf="documentsVisita && documentsVisita[0]"
            class="alert alert-warning w-100 p-2 my-2">
            <b>Aviso:</b> se borrarán los documentos anteriores.
        </div>
        </ng-template>
    </p-fileUpload>
</p-dialog>


<div id="gotodate-div">
    <p>Ir a fecha:</p>
    <p-dropdown id="month-dropdown" [options]="months" [(ngModel)]="selectedMonth" placeholder="Mes" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"
    [disabled]="isLoading"></p-dropdown>
    <p-dropdown id="year-dropdown" [options]="years" [(ngModel)]="selectedYear" placeholder="Año" 
    optionLabel="name" [showClear]="true" (onChange)="showSelectedDate(calendar)"
    [disabled]="isLoading"></p-dropdown>
    <div *ngIf="showVisitasMostrarTodas" class="d-flex justify-content-start align-items-center">
        <p-inputSwitch
                [style]="{marginLeft: '15px', marginRight: '10px'}"
                [(ngModel)]="showAllVisitas"
                (onChange)="changeShowAll();filterArray()">
        </p-inputSwitch>
        <p style="color: white; font-size: 1.2em; margin-bottom: 0">Mostrar todas</p>
        
        <div *ngIf="citasApi.citas.GET.isLoading" class="d-flex align-items-center">
            <span style="color: #2c2c2c;">Cargando... &nbsp;</span>
            <div style="width: .8rem; height: auto;">
                <img src="assets/icon/load.gif" style="width: 100%; height: auto;" alt="cargando"/>
            </div>
        </div>

    </div>
</div>

<div class="row" style="position: sticky; top: 0; right: 0; width: 100%;">
    <div class="col-lg-7 col-sm-4 me-5">
        <div *ngIf="isLoading" id="is-loading-screen">
            <h4 style="text-align: center">Cargando citas...</h4>
            <img src="assets/icon/load.gif" style="width: 64px; height: auto;" alt="cargando"/>
        </div>

        <full-calendar #calendar
                [events]="citasArrayFiltered"
                [options]="options">
        </full-calendar>
    </div>
    <div class="col-lg-3 col-sm-9 float-right">
        <p-panel>

            <div style="max-height: 80vh; overflow-y: auto; overflow-x: hidden;">
                <div class="w-100">
                    <div class="d-flex justify-content-between align-items-center">
                        <h2>Datos de la cita</h2>
                        <div>
                            
                        <a *ngIf="showLocationLatLng &&  (citaSeleccionada?.visita?.lat?.length) 
                            && 
                            (citaSeleccionada?.visita?.lng?.length)"  tooltipPosition="top" pTooltip="Mapa" href="https://www.google.es/maps/search/?api=1&query={{ citaSeleccionada?.visita?.lat }},{{ citaSeleccionada?.visita?.lng}}" target="_blank">
                            <img src="assets/icon/maps2.png" height="35px" style="margin-right: 8px;" alt="Icono de Mapa"></a>
                            <button *ngIf="citaSeleccionada?.id && (
                                        showGenerarRegistroVisita || showGenerarRecetaVisita
                                    )"
                                    [disabled]="!citaSeleccionada?.visita" 
                                    tooltipPosition="top" pTooltip="Ver impreso"
                                    (click)="showVisita()"
                                    class="btn mr-3"
                                    style="height: 30px; width: 30px;">
                                <i class="fa fa-file-text"   style="
                                position: relative;
                                bottom: 4px;
                                right: 4px;" alt="pdf"></i>
                            </button>

                            <button *ngIf="citaSeleccionada?.id"
                                    (click)="showEliminaCita=true"
                                    tooltipPosition="top" pTooltip="Borrar"
                                    class="btn btn-danger mr-3"
                                    style="height: 30px; width: 30px;">
                                <i class="fa fa-trash"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>
    
                            <button *ngIf="citaSeleccionada"
                                    (click)="editCita(citaSeleccionada)"
                                    tooltipPosition="top" pTooltip="Editar fecha y hora"
                                    class="btn btn-dark mr-3"
                                    style="height: 30px; width: 30px">
                                <i class="fa fa-pencil"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>
    
                            <button *ngIf="citaSeleccionada?.id && showVisitasUploadPhotos"
                                    (click)="showUpload()"
                                    tooltipPosition="top" pTooltip="Subir fotos"
                                    class="btn btn-info"
                                    style="height: 30px; width: 30px">
                                <i class="fa fa-upload"
                                style="
                                    position: relative;
                                    bottom: 4px;
                                    right: 4px;">
                                </i>
                            </button>

                            <button *ngIf="citaSeleccionada?.id && showDocumentUploadVisitas"
                                    [disabled]="!citaSeleccionada?.visita" 
                                    tooltipPosition="top" pTooltip="Subir Documento"
                                    (click)="showUploadDocument()"
                                    class="btn mr-3"
                                    style="padding: 0;
                                    border: none;
                                    background: none;
                                    cursor: pointer;
                                    height: 30px; width: 30px; 
                                    margin-left: 0.5em;
                                    color: white;">
                                <img src="assets/icon/pdf.png"  style="width: 100%; " alt="pdf">
                            </button>
    
                        </div>
                    </div>
                    
                  
    
                    
                    <br />
                    <h6 *ngIf="showVisitasARealizar && showVisitasVariedadMes" class="mb-2">Visitas realizadas: {{visitasRealizadas}}/{{visitasARealizar}}</h6>
                    <button *ngIf="!showVisitasPlagas && citaSeleccionada?.visita?.id"
                            (click)="editVisita()"
                            class="float-right btn btn-secondary"
                            style="padding: 5px; font-size: .8rem;">
                            Editar datos
                    </button>
                    <h6>Visitador: {{citaSeleccionada?.visitador?.nombre}}</h6>                    
                    <h6>Fecha: {{citaSeleccionada?.fecha}}</h6>
                    <h6 *ngIf="showHorasVisitas">
                        Hora: {{citaSeleccionada?.hora}}
                    </h6>
                    <h6 *ngIf="hasClients">
                        Cliente: {{citaSeleccionada?.cliente?.nombre}}
                    </h6>
                    <h6 *ngIf="appName === 'previlsa'">
                        Tipo de visita: {{citaSeleccionada?.tipo_visita}}
                    </h6>
                    <h6 *ngIf="appName === 'previlsa'">
                        Notas: <span [innerHtml]= "citaSeleccionada?.observaciones"></span>
<!--                          {{citaSeleccionada?.observaciones}} -->
                    </h6>
                    <h6 *ngIf="hasFincas">
                        Finca: {{citaSeleccionada?.finca?.nombre}}
                    </h6>
                    <h6 *ngIf="showVisitasParcelas">
                        Parcela: {{citaSeleccionada?.parcela}}
                    </h6>                      
                    <h6 *ngIf="showVisitasCultivos">
                        Cultivo: {{ (citaSeleccionada?.cultivo?.cultivo) ?citaSeleccionada?.cultivo?.cultivo: citaSeleccionada?.variedad?.cultivo }}
                    </h6>
                    <h6 *ngIf="showVisitasVariedades && (citaSeleccionada?.variedad_nombre || citaSeleccionada?.variedad?.variedad)">
                        Variedad: {{citaSeleccionada?.variedad_nombre || citaSeleccionada?.variedad?.variedad}}
                    </h6>


                    <h6 *ngIf="showMotivo">
                        Motivo: {{citaSeleccionada?.motivo}}
                    </h6>
                    <h6 *ngIf="showContadorLectura">
                        Lectura contador: {{citaSeleccionada?.visita?.lectura_contador}}
                    </h6> 
                    
                    <h6 *ngIf="appName === 'ava'"> Documento: <a  [href]="citaSeleccionada?.visita?.document" target="_blank" >Ver documento</a></h6>
                </div>

                <div *ngIf="showVisitasPlagas && citaSeleccionada?.visita">
                    <div class="row">
                        <div *ngIf="citaSeleccionada?.visita?.observaciones" class="col-12">
                            <h6>Observaciones: <span [innerHtml]= "citaSeleccionada?.visita?.observaciones"></span></h6>            
                        </div>
                        <div *ngIf="citaSeleccionada?.visita?.estado_fisiologico" class="col-12">
                            <h6>{{this.appName === 'pedrosaez' ? 'Estado Fisiológico del Cereal:' : 'Estado Fisiológico:'}} <span [innerHtml]= "citaSeleccionada?.visita?.estado_fisiologico"></span></h6>            
                        </div>
                        <div *ngIf="citaSeleccionada?.visita?.estado_fisiologico_cebolla" class="col-12">
                            <h6>Estado Fisiológico de la Cebolla: <span [innerHtml]= "citaSeleccionada?.visita?.estado_fisiologico_cebolla"></span></h6>            
                        </div>
                        <div *ngIf="citaSeleccionada?.visita?.estado_fisiologico" class="col-12">
                            <h6>Estado del Bulbo de la Cebolla: <span [innerHtml]= "citaSeleccionada?.visita?.estado_bulbo_cebolla"></span></h6>            
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div *ngIf="!showVisitasCamposPersonalizados" class="col-6">
                            <h6>
                                {{(appName === 'esparragosgranada') ? 'Estado de la Plantación' : 'Estado del arbolado'}}
                                {{citaSeleccionada?.visita?.estado_arbolado}}
                            </h6>
                            <h6 *ngIf="showNumTallos">Nº Tallos: {{citaSeleccionada?.visita?.num_tallos}}</h6>
                            <h6 *ngIf="showCosecha">Estado Cosecha: {{citaSeleccionada?.visita?.estado_cosecha}}</h6>
                            <h6>
                                {{(appName === 'esparragosgranada') ? 'Calibre' : 'Tamaño'}}:
                                {{citaSeleccionada?.visita?.tamanyo}}
                            </h6>
                            <h6 *ngIf="showVisitasPascualCabedo">Calibre de la fruta: {{citaSeleccionada?.visita?.tamano_fruta}}</h6>
                            <h6>Humedad: {{citaSeleccionada?.visita?.humedad}}</h6>
                            <h6>Floración: {{citaSeleccionada?.visita?.estado_floracion}}</h6>
                            <h6 *ngIf="showMoviendo">Moviendo: {{citaSeleccionada?.visita?.moviendo}}</h6>
                            <h6>Calidad Agua: {{citaSeleccionada?.visita?.calidad_agua || '-'}} mS</h6>                        
                            <h6 *ngIf="showVisitasContadorAgua">Contador agua: {{citaSeleccionada?.visita?.contador_agua}}</h6>                        
                            <h6 *ngIf="showVisitasFisiopatias">Fisiopatías: {{citaSeleccionada?.visita?.fisiopatias_nombres}}</h6>                        
                        </div>
                        <div *ngIf="appName === 'armentia'" class="col-6">
                            <h6>Estado desarrollo cultivo: {{citaSeleccionada?.visita?.estado_desarrollo_cultivo}}</h6>
                            <h6>Fecha de siembra: {{citaSeleccionada?.visita?.fecha_siembra}}</h6>
                            <h6>Dosis de siembra: {{citaSeleccionada?.visita?.dosis_siembra}}</h6>
                            <h6>Cultivo precedente: {{citaSeleccionada?.cultivo_precedente?.cultivo}}</h6>
                        </div>
                        <div *ngIf="showVisitasCamposPersonalizados" class="col-6">
                            <h6>Previsión de aforo: {{citaSeleccionada?.visita?.kg_recolectados}}</h6>
                            <h6>Calibre medio fruto: {{citaSeleccionada?.visita?.calibre_medio_fruto}}</h6>
                            <h6>Calibre aclareo: {{citaSeleccionada?.visita?.calibre_aclareo}}</h6>
                            <h6>Caída hojas suelo: {{citaSeleccionada?.visita?.caida_hojas_suelo}}</h6>
                            <h6>Aplicar nutrientes general: {{(citaSeleccionada?.visita?.aplicar_nutrientes_general === '1') ? 'Sí' : 'No'}}</h6>
                            <!-- <h6 *ngIf="this.appName !== 'ingenia'">Cítricos: {{citaSeleccionada?.visita?.citricos}}</h6> -->
                            <h6>Estado fenologico: {{citaSeleccionada?.visita?.estado_fenologico}}</h6>
                            <h6 *ngIf="this.showEstadoFenologicoAlmendro">Almendro: {{citaSeleccionada?.visita?.estado_fenologico_almendro}}</h6>
                            <h6 *ngIf="this.showEstadoFenologicoVid">Vid: {{citaSeleccionada?.visita?.estado_fenologico_vid}}</h6>
                            <h6 *ngIf="!hiddenVisitasCerezo">Cerezos: {{citaSeleccionada?.visita?.cerezos}}</h6>
                            <h6 *ngIf="this.appName === 'sequiador'  || this.appName === 'SIEX'">Aclareo: {{citaSeleccionada?.visita?.aclareo}}</h6>
                            <h6 *ngIf="this.applicationType === 'cropgest'">Malas Hierbas: {{citaSeleccionada?.visita?.estado_malas_hierbas}}</h6>
                            <h6 *ngIf="!showVisitaCarenciasMultiple">Carencias: {{citaSeleccionada?.visita?.carencias_nombres}}</h6>
                            <div *ngIf="showVisitaCarenciasMultiple">
                                <h6>Carencia 1: {{citaSeleccionada?.visita?.carencia1}} ({{citaSeleccionada?.visita?.porcentaje_carencia1}}%)</h6> 
                                <h6>Carencia 2: {{citaSeleccionada?.visita?.carencia2}} ({{citaSeleccionada?.visita?.porcentaje_carencia2}}%)</h6> 
                                <h6>Carencia 3: {{citaSeleccionada?.visita?.carencia3}} ({{citaSeleccionada?.visita?.porcentaje_carencia3}}%)</h6> 
                             </div>
                            <h6 *ngIf="!showVisitaCarenciasMultiple">Porcentaje de carencia: {{citaSeleccionada?.visita?.porcentaje_carencia || '-'}} %</h6>
                            <h6>Fisiopatías: {{citaSeleccionada?.visita?.fisiopatias_nombres}}</h6>
                            <h6>Lectura contador: {{citaSeleccionada?.visita?.lectura_contador}}</h6>                            
                        </div>
                        <div *ngIf="showVisitasFormAbonosFolques"  class="col-6">
                            <h6>Previsión de aforo: {{citaSeleccionada?.visita?.kg_recolectados}}</h6>
                            <h6>Calibre medio fruto: {{citaSeleccionada?.visita?.calibre_medio_fruto}}</h6>
                            <h6>Calibre aclareo: {{citaSeleccionada?.visita?.calibre_aclareo}}</h6>
                            <h6>Caída hojas suelo: {{citaSeleccionada?.visita?.caida_hojas_suelo}}</h6>
                            <h6>Aplicar nutrientes general: {{(citaSeleccionada?.visita?.aplicar_nutrientes_general === '1') ? 'Sí' : 'No'}}</h6>
                            <h6>Fisiopatías: {{citaSeleccionada?.visita?.fisiopatias_nombres}}</h6>
                            <h6>Lectura contador: {{citaSeleccionada?.visita?.lectura_contador}}</h6> 
                            <div  *ngIf="showVisitaCarenciasMultiple">
                                <h6>Carencia 1: {{citaSeleccionada?.visita?.carencia1}} ({{citaSeleccionada?.visita?.porcentaje_carencia1}}%)</h6> 
                                <h6>Carencia 2: {{citaSeleccionada?.visita?.carencia2}} ({{citaSeleccionada?.visita?.porcentaje_carencia2}}%)</h6> 
                                <h6>Carencia 3: {{citaSeleccionada?.visita?.carencia3}} ({{citaSeleccionada?.visita?.porcentaje_carencia3}}%)</h6> 
                             </div>
                        </div>
                        <div *ngIf="isMelia" class="col-6">
                            <h6>Poda: {{citaSeleccionada?.visita?.poda}}</h6>
                            <!-- <h6>Estado fenológico: {{citaSeleccionada?.visita?.estado_fenologico}}</h6> -->
                            <h6>Cosecha: {{citaSeleccionada?.visita?.cosecha}}</h6>
                            <h6>Tamaño (mm): {{citaSeleccionada?.visita?.tamanyo}}</h6>
                            <h6>Humedad: {{citaSeleccionada?.visita?.humedad}}</h6>
                            <h6>Floración: {{citaSeleccionada?.visita?.estado_floracion}}</h6>
                        </div>
                        <div class="col-6">
                            <div class="d-flex justify-content-between align-items-center">
                                <h5>Plagas</h5>
                                <button *ngIf="citaSeleccionada?.visita?.id"
                                (click)="editVisita()"
                                tooltipPosition="top" pTooltip="Editar visita"
                                class="btn btn-dark"
                                style="height: 30px; width: 30px">
                                    <i class="fa fa-pencil"
                                    style="
                                        position: relative;
                                        bottom: 4px;
                                        right: 4px;">
                                    </i>
                                </button>
                            </div>
                            <h6 *ngIf="citaSeleccionada?.visita?.plaga1">
                                {{citaSeleccionada?.visita?.plaga1}}: {{citaSeleccionada?.visita?.estado_plaga1}}
                                {{citaSeleccionada?.visita?.cantidad1}}
                                <span *ngIf="citaSeleccionada?.visita?.estadio1">
                                    ({{citaSeleccionada?.visita?.estadio1}})
                                </span>
                            </h6>
                            <h6 *ngIf="citaSeleccionada?.visita?.plaga2">
                                {{citaSeleccionada?.visita?.plaga2}}: {{citaSeleccionada?.visita?.estado_plaga2}}
                                {{citaSeleccionada?.visita?.cantidad2}}
                                <span *ngIf="citaSeleccionada?.visita?.estadio2">
                                    ({{citaSeleccionada?.visita?.estadio2}})
                                </span>
                            </h6>                        
                            <h6 *ngIf="citaSeleccionada?.visita?.plaga3">
                                {{citaSeleccionada?.visita?.plaga3}}: {{citaSeleccionada?.visita?.estado_plaga3}}
                                {{citaSeleccionada?.visita?.cantidad3}}
                                <span *ngIf="citaSeleccionada?.visita?.estadio3">
                                    ({{citaSeleccionada?.visita?.estadio3}})
                                </span>
                            </h6>
                            <h6 *ngIf="citaSeleccionada?.visita?.plaga4">
                                {{citaSeleccionada?.visita?.plaga4}}: {{citaSeleccionada?.visita?.estado_plaga4}}
                                {{citaSeleccionada?.visita?.cantidad4}}
                                <span *ngIf="citaSeleccionada?.visita?.estadio4">
                                    ({{citaSeleccionada?.visita?.estadio4}})
                                </span>
                            </h6>
                        </div>
                        
                    </div>
                    <hr />
                    <div *ngIf="(imagenesVisita.length > 0) && !imagenesVisita[0].push" class="d-flex justify-content-center">
                        <div *ngFor="let image of imagenesVisita[0]">
                            <div *ngIf="image" class="d-flex align-items-center img-container">
                                <button (click)="showImage(image)">
                                    <img class="img-item" [src]="image?.source" alt="">
                                </button>
                            </div>
                            <p style="text-align: center; margin-left: 10px; font-size: 0.8em">{{image?.title}}</p>
                        </div>
                    </div>
        
                    <!-- Wrapper -->
                    <div *ngIf="imagenesVisita"
                        style="max-height: 200px; overflow-y: auto;">
                        <!-- Container -->
                        <div class="d-flex justify-content-left flex-wrap">
                            <!-- Firma -->
                            <div *ngIf="imagenesVisita[1]">
                                <div class="d-flex align-items-center img-container">
                                    <button (click)="showImage(imagenesVisita[1])">
                                        <img class="img-item" [src]="imagenesVisita[1].source" alt="">
                                    </button>
                                </div>
                                <p style="text-align: center; margin-left: 10px; font-size: 0.8em">{{imagenesVisita[1].title}}</p>
                            </div>
                            <!-- Fotos -->
                            <div *ngIf="imagenesVisita[0]" class="w-100 d-flex flex-wrap">
                                <button *ngFor="let subImage of imagenesVisita[0]" class="mx-1" (click)="showImage(subImage)">
                                    <div *ngIf="subImage" class="d-flex justify-content-center align-items-center img-container">
                                        <img class="img-item" [src]="subImage?.source" alt="">
                                    </div>
                                    <p style="text-align: center; margin-left: 10px; font-size: 0.8em">{{subImage?.title}}</p>
                                </button>
                            </div>
                        </div>
        
                    </div>
                </div>
            </div>
    
        </p-panel>
    </div>
</div>

<div class="row" style="width: auto; overflow-y: auto; overflow-x: hidden; min-height: 60vh;">
    
</div>
<br>

<p-dialog *ngIf="showGenerarRegistroVisita" header="Registro de la visita" [(visible)]="showRegistroVisita" (onShow)="scrollToTop()">
    <div style="margin-left: 20px; margin-right: 20px; width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">

            <div class="registro-flex" style="justify-content: space-around;">
                <div>
                    <img *ngIf="appName === 'lafast'" src="assets/logo_coplaca_natur_2.png" style="width: 120px; height: auto;" alt="Logo Coplaca"/>
                    <img *ngIf="appName !== 'lafast'" [src]="logoUrl" style="width: 120px; height: auto;" alt="Logo"/>
                </div>
                <table class="registro-table-general" style="width: 66%">
                    <tr>
                        <td>FECHA DE LA VISITA: {{citaSeleccionada?.fecha}}</td>
                        <td>AÑO/Nº VISITA: {{citaSeleccionada?.numVisita}}</td>
                    </tr>
                    <tr>
                        <td>TITULAR: {{citaSeleccionada?.cliente_nombre}}</td>
                        <td>CÓDIGO DE TRAZABILIDAD: {{'000' + citaSeleccionada?.cliente?.codigo}}</td>
                    </tr>
                    <tr>
                        <td>FINCA: {{citaSeleccionada?.finca_nombre}}</td>
                        <td>PARCELA/S VISITA: {{citaSeleccionada?.id_sector}}</td>
                    </tr>
                </table>
            </div>

            <p>1.- ESTADO FITOSANITARIO</p>
            
            <div class="registro-flex">

                <table class="registro-table" border="1" style="width: 66%">
                    <thead>
                        <th style="width: 32%">PLAGAS</th>
                        <th style="width: 5%; text-align: center">1</th>
                        <th style="width: 5%; text-align: center">2</th>
                        <th style="width: 5%; text-align: center">3</th>
                        <th style="width: 38%">ENFERMEDADES</th>
                        <th style="width: 5%; text-align: center">1</th>
                        <th style="width: 5%; text-align: center">2</th>
                        <th style="width: 5%; text-align: center">3</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Cochinilla</td>
                            <td style="text-align: center">{{plagas.includes('Cochinilla_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Cochinilla_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Cochinilla_Control') ? 'x' : ''}}</td>
                            <td>Fusarium</td>
                            <td style="text-align: center">{{plagas.includes('Fusarium_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Fusarium_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Fusarium_Control') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Lagarta</td>
                            <td style="text-align: center">{{plagas.includes('Lagarta_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Lagarta_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Lagarta_Control') ? 'x' : ''}}</td>
                            <td>-</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Mosca Blanca</td>
                            <td style="text-align: center">{{plagas.includes('Mosca Blanca_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Mosca Blanca_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Mosca Blanca_Control') ? 'x' : ''}}</td>
                            <td class="dark-bg">OTROS</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Araña Roja</td>
                            <td style="text-align: center">{{plagas.includes('Araña Roja_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Araña Roja_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Araña Roja_Control') ? 'x' : ''}}</td>
                            <td>Malas Hierbas</td>
                            <td style="text-align: center">{{plagas.includes('Malas Hierbas_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Malas Hierbas_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Malas Hierbas_Control') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Thrips</td>
                            <td style="text-align: center">{{plagas.includes('Thrips_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Thrips_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Thrips_Control') ? 'x' : ''}}</td>
                            <td>Nematodos</td>
                            <td style="text-align: center">{{plagas.includes('Nematodos_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Nematodos_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Nematodos_Control') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Picudo</td>
                            <td style="text-align: center">{{plagas.includes('Picudo_Bajo') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Picudo_Vigilancia') ? 'x' : ''}}</td>
                            <td style="text-align: center">{{plagas.includes('Picudo_Control') ? 'x' : ''}}</td>
                            <td>-</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>-</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="8">
                                1.- NIVEL BAJO · 2.- VIGILANCIA · 3.- APLICAR CONTROL
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table class="registro-table" border="1" style="width: 30%">
                    <thead>
                        <th colspan="2">AUXILIARES</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td style="width: 50%">Crisopas</td>
                            <td style="text-align: center">{{plagas.includes('Crisopas_Hay') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Sífridos</td>
                            <td style="text-align: center">{{plagas.includes('Sífridos_Hay') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Fitoseidos</td>
                            <td style="text-align: center">{{plagas.includes('Fitoseidos_Hay') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Trichogramma</td>
                            <td style="text-align: center">{{plagas.includes('Trichogramma_Hay') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>Aves</td>
                            <td style="text-align: center">{{plagas.includes('Aves_Hay') ? 'x' : ''}}</td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td colspan="2">Indicar si hay o no</td>
                        </tr>
                    </tbody>
                </table>


            </div>

            <br>
            
            <b>Observaciones:</b>
            
            <br>
            
            <div class="big_input">
                <span [innerHtml]= "citaSeleccionada?.visita?.observaciones"></span>
<!--                 {{citaSeleccionada?.visita?.observaciones}} -->
            </div>

            <p>2.- ESTADO NUTRICIONAL Y/O FISIOLÓGICO</p>

            <div class="big_input">
                {{citaSeleccionada?.visita?.estado_nutricional}}
            </div>

            <p>3.- INSTRUCCIONES DE MANEJO (riego, labores de cultivo, instalaciones...)</p>

            <table class="table-flex">
                <tr>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Deshijado')"/> 
                        <span>Deshijado</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Desflorillado')"> 
                        <span>Desflorillado</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Cortar rolos')"/> 
                        <span>Cortar rolos</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Embolsado')"/> 
                        <span>Embolsado</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Deshojado')"/> 
                        <span>Deshojado</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Desinfección')"/> 
                        <span>Desinfección</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Amarre')"/> 
                        <span>Amarre</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Horconado')"/> 
                        <span>Horconado</span>
                    </td>
                    <td>
                        <input type="checkbox" [checked]="instrucciones_manejo.includes('Revisar riego')"/> 
                        <span>Revisar riego</span>
                    </td>
                </tr>
            </table>

            <div class="registro-flex">
                <table class="registro-table" style="width: 63%">
                    <tr>
                        <td>
                            <p>Firma del encargado</p>
                            <small style="position: absolute">{{citaSeleccionada?.cliente_nombre}}, con NIF {{citaSeleccionada?.cliente?.nif}}</small>
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 6rem; border-bottom: 1px solid #0c0c0c"></td>
                    </tr>
                </table>
                <table class="registro-table" style="width: 33%">
                    <tr>
                        <td>
                            <p>Firma y Nº ROPO del técnico asesor</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="height: 6rem; border-bottom: 1px solid #0c0c0c">
                            <span style="position: relative; top: 2rem;">Nº ROPO: {{citaSeleccionada?.visitador?.ropo}}</span>
                        </td>
                    </tr>
                </table>

            </div>

        </div> <!-- /registro div -->

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showRegistroVisita = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirVisita();">Imprimir</button>
        </div>
        
        <br>

        <h6 class="text-center small">
            Por favor, antes de hacer click en <b>Imprimir</b>, asegúrese de maximizar
            esta ventana para que la imagen resultante quede del tamaño correcto.
        </h6>
    </div>
</p-dialog>

<p-dialog *ngIf="showGenerarRecetaVisita" header="Registro de la visita" [(visible)]="showRegistroVisita" (onShow)="scrollToTop()">
    <div class="mb-4 p-3" style="width: 80vw !important;">
        <div id="html2canvasDiv" class="registroDiv">
            <h2 class="mb-3">Datos de la visita</h2>
            <div class="w-100 d-flex justify-content-between">
                <div style="flex: 1;" class="p-2">
                    <h6 *ngIf="showVisitasARealizar && showVisitasVariedadMes">Visitas realizadas: {{visitasRealizadas}}/{{visitasARealizar}}</h6>
                    <button *ngIf="!showVisitasPlagas && citaSeleccionada?.visita?.id"
                            (click)="editVisita()"
                            class="float-right btn btn-secondary"
                            style="padding: 5px; font-size: .8rem;">
                                Editar datos
                    </button>
                    <h6>Visitador: {{citaSeleccionada?.visitador?.nombre}}</h6>
                    <h6>Fecha: {{citaSeleccionada?.fecha}}</h6>
                    <h6 *ngIf="showHorasVisitas">Hora: {{citaSeleccionada?.hora}}</h6>
                    <h6 *ngIf="hasClients">Cliente: {{citaSeleccionada?.cliente?.nombre}}</h6>
                    <h6 *ngIf="hasFincas">Finca: {{citaSeleccionada?.finca?.nombre}}</h6>
                    <h6 *ngIf="showVisitasParcelas">Parcela: {{citaSeleccionada?.parcela}}</h6>                      
                    <h6>Variedad: {{citaSeleccionada?.variedad_nombre || citaSeleccionada?.variedad?.variedad}}</h6>
                    <h6>Observaciones: <span [innerHtml]= "citaSeleccionada?.visita?.observaciones"></span></h6>
<!--                     <h6>Observaciones: {{citaSeleccionada?.visita?.observaciones}}</h6> -->
                    
                </div>
              
                <div *ngIf="!showVisitasCamposPersonalizados" class="p-2" style="flex: 1;">
                    <h6>
                        {{(appName === 'esparragosgranada') ? 'Estado de la Plantación' : 'Estado del arbolado'}}
                        {{citaSeleccionada?.visita?.estado_arbolado}}
                    </h6>
                    <h6 *ngIf="showNumTallos">Nº Tallos: {{citaSeleccionada?.visita?.num_tallos}}</h6>
                    <h6 *ngIf="showCosecha">Estado Cosecha: {{citaSeleccionada?.visita?.estado_cosecha}}</h6>
                    <h6>
                        {{(appName === 'esparragosgranada') ? 'Calibre' : 'Tamaño'}}:
                        {{citaSeleccionada?.visita?.tamanyo}}
                    </h6>
                    <h6>Humedad: {{citaSeleccionada?.visita?.humedad}}</h6>
                    <h6>Floración: {{citaSeleccionada?.visita?.estado_floracion}}</h6>
                    <h6 *ngIf="showMoviendo">Moviendo: {{citaSeleccionada?.visita?.moviendo}}</h6>
                    <h6>Calidad Agua: {{citaSeleccionada?.visita?.calidad_agua}} mS</h6>   
                </div>

                <div *ngIf="appName === 'armentia'" class="col-6">
                    <h6>Estado desarrollo cultivo: {{citaSeleccionada?.visita?.estado_desarrollo_cultivo}}</h6>
                    <h6>Fecha de siembra: {{citaSeleccionada?.visita?.fecha_siembra}}</h6>
                    <h6>Dosis de siembra: {{citaSeleccionada?.visita?.dosis_siembra}}</h6>
                    <h6>Cultivo precedente: {{citaSeleccionada?.cultivo_precedente?.cultivo}}</h6>
                </div>
                <div *ngIf="appName === 'abonosfolques'" class="col-6">
                    <h6>Previsión de aforo: {{citaSeleccionada?.visita?.kg_recolectados}}</h6>
                    <h6>Calibre medio fruto: {{citaSeleccionada?.visita?.calibre_medio_fruto}}</h6>
                    <h6>Calibre aclareo: {{citaSeleccionada?.visita?.calibre_aclareo}}</h6>
                    <h6>Caída hojas suelo: {{citaSeleccionada?.visita?.caida_hojas_suelo}}</h6>
                    <h6>Aplicar nutrientes general: {{(citaSeleccionada?.visita?.aplicar_nutrientes_general === '1') ? 'Sí' : 'No'}}</h6>
                    <h6>Cítricos: {{citaSeleccionada?.visita?.citricos}}</h6>
                    <h6>Cerezos: {{citaSeleccionada?.visita?.cerezos}}</h6>
                    <h6>Carencias: {{citaSeleccionada?.visita?.carencias_nombres}}</h6>
                    <h6>Porcentaje de carencia: {{citaSeleccionada?.visita?.porcentaje_carencia || '-'}} %</h6>
                    <h6>Fisiopatías: {{citaSeleccionada?.visita?.fisiopatias_nombres}}</h6>
                </div>
                <div *ngIf="showVisitasCamposPersonalizados"  class="col-4">
                            <h6>Previsión de aforo: {{citaSeleccionada?.visita?.kg_recolectados}}</h6>
                            <h6>Calibre medio fruto: {{citaSeleccionada?.visita?.calibre_medio_fruto}}</h6>
                            <h6>Calibre aclareo: {{citaSeleccionada?.visita?.calibre_aclareo}}</h6>
                            <h6>Caída hojas suelo: {{citaSeleccionada?.visita?.caida_hojas_suelo}}</h6>
                            <h6>Aplicar nutrientes general: {{(citaSeleccionada?.visita?.aplicar_nutrientes_general === '1') ? 'Sí' : 'No'}}</h6>
                            <!-- <h6>Cítricos: {{citaSeleccionada?.visita?.citricos}}</h6> -->
                            <h6>Estado fenologico: {{citaSeleccionada?.visita?.estado_fenologico}}</h6>
                            <h6 *ngIf="!hiddenVisitasCerezo">Cerezos: {{citaSeleccionada?.visita?.cerezos}}</h6>
                            <h6>Fisiopatías: {{citaSeleccionada?.visita?.fisiopatias_nombres}}</h6>
                            <h6>Lectura contador: {{citaSeleccionada?.visita?.lectura_contador}}</h6> 
                            <div  *ngIf="showVisitaCarenciasMultiple">
                                <h6>Carencia 1: {{citaSeleccionada?.visita?.carencia1}} ({{citaSeleccionada?.visita?.porcentaje_carencia1}}%)</h6> 
                                <h6>Carencia 2: {{citaSeleccionada?.visita?.carencia2}} ({{citaSeleccionada?.visita?.porcentaje_carencia2}}%)</h6> 
                                <h6>Carencia 3: {{citaSeleccionada?.visita?.carencia3}} ({{citaSeleccionada?.visita?.porcentaje_carencia3}}%)</h6> 
                             </div>
                        </div>
                <div *ngIf="isMelia" class="p-2" style="flex: 1">
                    <h6>Poda: {{citaSeleccionada?.visita?.poda}}</h6>
                    <!-- <h6>Estado fenológico: {{citaSeleccionada?.visita?.estado_fenologico}}</h6> -->
                    <h6>Cosecha: {{citaSeleccionada?.visita?.cosecha}}</h6>
                    <h6>Tamaño (mm): {{citaSeleccionada?.visita?.tamanyo}}</h6>
                    <h6>Humedad: {{citaSeleccionada?.visita?.humedad}}</h6>
                    <h6>Floración: {{citaSeleccionada?.visita?.estado_floracion}}</h6>
                </div>

                <div style="flex: 1;" class="p-2">
                    <h5 class="font-weight-bold">Plagas</h5>
                    <h6 *ngIf="citaSeleccionada?.visita?.plaga1">
                        {{citaSeleccionada?.visita?.plaga1}}: {{citaSeleccionada?.visita?.estado_plaga1}}
                        {{citaSeleccionada?.visita?.cantidad1}}
                        <span *ngIf="citaSeleccionada?.visita?.estadio1">
                            ({{citaSeleccionada?.visita?.estadio1}})
                        </span>
                    </h6>
                    <h6 *ngIf="citaSeleccionada?.visita?.plaga2">
                        {{citaSeleccionada?.visita?.plaga2}}: {{citaSeleccionada?.visita?.estado_plaga2}}
                        {{citaSeleccionada?.visita?.cantidad2}}
                        <span *ngIf="citaSeleccionada?.visita?.estadio2">
                            ({{citaSeleccionada?.visita?.estadio2}})
                        </span>
                    </h6>                        
                    <h6 *ngIf="citaSeleccionada?.visita?.plaga3">
                        {{citaSeleccionada?.visita?.plaga3}}: {{citaSeleccionada?.visita?.estado_plaga3}}
                        {{citaSeleccionada?.visita?.cantidad3}}
                        <span *ngIf="citaSeleccionada?.visita?.estadio3">
                            ({{citaSeleccionada?.visita?.estadio3}})
                        </span>
                    </h6>
                    <h6 *ngIf="citaSeleccionada?.visita?.plaga4">
                        {{citaSeleccionada?.visita?.plaga4}}: {{citaSeleccionada?.visita?.estado_plaga4}}
                        {{citaSeleccionada?.visita?.cantidad4}}
                        <span *ngIf="citaSeleccionada?.visita?.estadio4">
                            ({{citaSeleccionada?.visita?.estadio4}})
                        </span>
                    </h6>
                </div>
            </div>

            <br />
        </div>
        <div *ngIf="showVisitasPlagas && citaSeleccionada?.visita">
            <div *ngIf="imagenesVisita.length > 0 && !imagenesVisita[0].push" class="d-flex justify-content-center">
                <div *ngFor="let image of imagenesVisita[0]">
                    <div *ngIf="image" class="d-flex align-items-center img-container">
                        <button (click)="showImage(image)">
                            <img class="img-item" [src]="image?.source" alt="">
                        </button>
                    </div>
                    <p style="text-align: center; margin-left: 10px; font-size: 0.8em">{{image?.title}}</p>
                </div>
            </div>

            <!-- Wrapper -->
            <div *ngIf="imagenesVisita">
                <!-- Container -->
                <div class="d-flex justify-content-left flex-wrap">
                    <!-- Firma -->
                    <div *ngIf="imagenesVisita[1]">
                        <div class="mx-1 d-flex align-items-center img-container">
                            <button (click)="showImage(imagenesVisita[1])">
                                <img class="img-item" [src]="imagenesVisita[1].source" alt="">
                            </button>
                        </div>
                        <p class="text-center mx-1 small">{{imagenesVisita[1].title}}</p>
                    </div>
                    <!-- Fotos -->
                    <div *ngIf="imagenesVisita[0]">
                        <div *ngFor="let subImage of imagenesVisita[0]">
                            <div class="mx-1 d-flex align-items-center img-container">
                                <button (click)="showImage(subImage)">
                                    <img class="img-item" [src]="subImage?.source" alt="">
                                </button>
                            </div>
                            <p class="text-center mx-1 small">{{subImage?.title}}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showRegistroVisita = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirVisita();">Imprimir</button>
            <button *ngIf="showEnviarWhatsappVisitas" class="btn btn-success mx-2" (click)="enviarWhatsapp();">
                <img style="width: 24px; height: 24px" src="./assets/whatsapp_48x48.png" alt="Icono WhatsApp">
                Enviar WhatsApp
            </button>
            <button *ngIf="showEnviarCorreoVisitas"
                    [disabled]="tareasApi.enviarRecetaCaptura.isLoading || isGeneratingCanvas || 
                        !(citaSeleccionada?.finca?.email || citaSeleccionada?.cliente?.email)"
                    (click)="enviarCorreo()"
                    class="btn mx-2"
                    [ngClass]="tareasApi.enviarRecetaCaptura.hasError ? 'btn-danger' : 'btn-warning'">

                <ng-container *ngIf="!tareasApi?.enviarRecetaCaptura?.isLoading && !tareasApi?.enviarRecetaCaptura?.hasError"
                    tooltipPosition="top" pTooltip="Se enviará la visita a este email">
                    <i class="fa fa-envelope"></i>
                    <span *ngIf="isGeneratingCanvas">
                        Generando pdf...
                    </span>
                    <span *ngIf="!isGeneratingCanvas && (citaSeleccionada?.finca?.email || citaSeleccionada?.cliente?.email)">
                        Enviar a {{citaSeleccionada?.finca?.email ? citaSeleccionada?.finca?.email : citaSeleccionada?.cliente?.email}}
                    </span>
                    <span *ngIf="!isGeneratingCanvas && !(citaSeleccionada?.finca?.email || citaSeleccionada?.cliente?.email)">
                        Enviar email
                    </span>
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.isLoading && !tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-envelope"></i>
                    Enviando...
                </ng-container>
                <ng-container *ngIf="tareasApi?.enviarRecetaCaptura?.hasError">
                    <i class="fa fa-exclamation-triangle"></i>
                    {{tareasApi.enviarRecetaCaptura.error}}
                </ng-container>
            </button>
            <br>
        </div>
    </div>
    
</p-dialog>

<a href="#" id="downloadVisita" style="visibility: hidden; position: fixed;"></a>