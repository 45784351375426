import {Component} from '@angular/core';
import {FormCommonApiService} from '../../../../service/api/formcommon-api.service';
import {DashboardService} from '../../../../service/events/dashboard.service';
import {InputType} from '../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../base-form';
import {ActivatedRoute, Router} from '@angular/router';
import {StockApiService} from '../../../../service/api/stock-api.service';
import {ProductosApiService} from '../../../../service/api/productos-api';
import {build, stack} from '../../../../common/classes/request-builder';
import {AppFormRequest} from '../../../app-common/form-request/app-form-request';
import {Utils} from '../../../../common/utils';
import {FincasApiService} from '../../../../service/api/fincas-api.service';
import { environment } from 'src/environments/environment';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../common/components/form-builder/form-builder.component';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { StockageModel } from 'src/app/models/stock/stokage.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-salidas-form',
    templateUrl: './salidas-form.component.html',
    styleUrls: ['./salidas-form.component.scss']
})
export class SalidasFormComponent extends BaseForm implements OnInit {

    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;

    public override model: StockageModel = {stockage_real: 0, stockage_teorico: 0, fecha: '', unidades_temp: 0, unidades: 0};
    public formRequest: AppFormRequest = new AppFormRequest();

    public fincas: TypeAheadInterface<FincasModel> = {
        selected: null,
        filtered: [
            {label: 'Cargando...', value: ''}
        ],
        values: []

    };

    public producto1: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [
            {label: 'Cargando...', value: ''}
        ],
        values: []

    };

    public producto2: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [
            {label: 'Cargando...', value: ''}
        ],
        values: []

    };

    public producto3: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [
            {label: 'Cargando...', value: ''}
        ],
        values: []

    };

    public tipo: TypeAheadInterface<ProductosModel> = {
        selected: null,
        filtered: [
            {label: 'Cargando...', value: ''}
        ],
        values: []

    };

    public formFields: ItemInterface<object>[] = [
        {
            field: 'id_finca',
            label: this.fincaName,
            inputType: {type: InputType.DROPDOWN},
            values: this.fincas,
            valuePrimaryKey: 'id'
        },

        {inputType: {type: InputType.EMPTY}},
        {inputType: {type: InputType.EMPTY}},


        // PRODUCTO 1
        {
            field: 'id_producto1',
            label: 'Producto',
            inputType: {type: InputType.DROPDOWN},
            values: this.producto1,
            valuePrimaryKey: 'id'
        },
        {
            field: 'unidades1',
            label: 'Unidades',
            inputType: {type: InputType.EDIT_TEXT}
        },

        // PRODUCTO 2
        {
            field: 'id_producto2',
            inputType: {type: InputType.DROPDOWN},
            values: this.producto2,
            valuePrimaryKey: 'id'
        },
        {
            field: 'unidades2',
            inputType: {type: InputType.EDIT_TEXT}
        },

        // PRODUCTO 3
        {
            field: 'id_producto3',
            inputType: {type: InputType.DROPDOWN},
            values: this.producto3,
            valuePrimaryKey: 'id'
        },
        {
            field: 'unidades3',
            inputType: {type: InputType.EDIT_TEXT}
        },


        {
            field: 'albaran', 
            label: 'Albarán', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'fecha', 
            label: 'Fecha', 
            inputType: {type: InputType.CALENDAR}
        },

        {
            field: 'observaciones', 
            label: 'Observaciones', 
            inputType: {type: InputType.EDIT_TEXTAREA}
        }
    ];


    constructor(
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService,
        public override router: Router,
        public override route: ActivatedRoute,
        public dashboardEvents: DashboardService,
        public stockApi: StockApiService
    ) {
        super(
            route,
            router,
            dashboardEvents,
            stockApi.salidas.PUT,
            stockApi.salidas.POST,
            'salidas',
            'Editar salida',
            'Crear salida'
        );
    }

    ngOnInit() {

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .isGeneric(true)
            .setGetRequest(this.stockApi.salidas.GET)
            .setPostRequest(this.stockApi.salidas.POST)
            .setPutRequest(this.stockApi.salidas.PUT)
            .setModel(this.model)
            .setFormFields(this.formFields)
            .setFieldsToSend((['id', 'id_usuario', 'tiposalida'] as string[])
                .concat(
                    this.formFields.map(it => it.field ?? '')
                )
            );

        this.formRequest.beforeLoad(resolve => {
            this.initFormData()
                .then(() => {
                    resolve(true);
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                });
        });

        this.formRequest.load();

        this.softInit(this.getType());
    }

    override goBack() {
        this.router.navigate(['dashboard', 'stock-salidas']);
    }

    public submit() {
        this.formRequest.beforeSend(resolve => {
            this.model.fecha = Utils.formatDate(this.model.fecha);
            this.model.tiposalida = 'S';
            resolve(true);
        });

        this.formRequest.send();
    }

    private initFormData() {
        return stack([
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(this.productosApi.productos.GET, [
                this.producto1,
                this.producto2,
                this.producto3
            ], 'nombre')
        ]);
    }
}